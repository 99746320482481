import { Router, Switch, Route } from 'react-router-dom'
import { history, routes } from '../../routes'
import { FC, memo } from 'react'
import ErrorBoundary from '~/layouts/errorBoundary'
import LoadableRouterComponent from '../loadableRouterComponent'
import { isLayoutRoute, RLRoute } from '~/routes/const'

function renderRoute(data: RLRoute[]) {
  return (
    <Switch>
      {data.map((router, index) => {
        if (isLayoutRoute(router)) {
          const { layout: Layout, redirect = true, children = [], path } = router
          return (
            <Route
              key={index}
              render={(props) => (
                <Layout>
                  {redirect && props.location.pathname === path && <LoadableRouterComponent {...props} redirect={redirect} />}
                  {renderRoute(children as RLRoute[])}
                </Layout>
              )}
            />
          )
        }

        // console.log(rest.path, rest)
        const { component, meta = {}, ...rest } = router
        return <Route {...rest} key={index} exact render={(props) => <LoadableRouterComponent {...props} component={component} meta={meta} />} />
      })}
      <Route path="*" render={(props) => <LoadableRouterComponent {...props} noMatch />} />
    </Switch>
  )
}

const Component: FC = () => {
  return (
    <Router history={history}>
      <ErrorBoundary>{renderRoute(routes)}</ErrorBoundary>
    </Router>
  )
}

Component.displayName = 'RouterLayout'

const RouterLayout = memo(Component)
export default RouterLayout
