import { FC, memo } from 'react'
import styles from './index.module.less'
import { ISettingDrawerFeildProps } from './const'

const Component: FC<ISettingDrawerFeildProps> = (props) => {
  // 通过解构定义defaultProps
  const { title = '' } = props

  return (
    <div className={styles.settingDrawerFeildStyle}>
      <div>{title}</div>
      <div>{props.children}</div>
    </div>
  )
}

Component.displayName = 'SettingDrawerFeild'

const SettingDrawerFeild = memo(Component)
export default SettingDrawerFeild
