import React, { FC, memo } from 'react';
import { Button, Result } from 'antd';
import styles from './index.module.less';
import { Link } from 'react-router-dom';
import { routeNames } from '~/routes/const';

const Component: FC = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="对不起,您访问的页面不存在"
      // extra={<Button type="primary"><Link to={routeNames.basicSetting} replace>回首页</Link></Button>}
      className={styles.component}
    />
  )
}

Component.defaultProps = {};
Component.displayName = 'MMPNOMatch';

const MMPNOMatch = memo(Component);
export default MMPNOMatch;