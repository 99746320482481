import loadable from '@loadable/component'
import { Spin } from 'antd'
import { Location, RouteBreadCrumb } from 'MMRouter'
import { createContext, PureComponent, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { skipAuth } from '~/config'
import globalContext, { IGlobalContext } from '~/contexts/global.context'
import { routeNames } from '~/routes/const'
import { routerAfterEach, routerBeforeEach } from '~/routes/guard'
import { getFirstPathFromMenus, getMenuBreadcrumbs } from '~/utils/menu'
import { IRouteContext, LoadableRouterComponentProps, LoadableRouterComponentState } from './const'
import NoMathRoute from './noMathRoute'

const Context = createContext({} as IRouteContext)
let from: Location

/**
 * 页面级懒加载路由
 * @export
 * @class LoadableRouterComponent
 * @extends {PureComponent<any>}
 */
export default class LoadableRouterComponent extends PureComponent<LoadableRouterComponentProps, LoadableRouterComponentState> {
  static contextType = globalContext

  static defaultProps: Partial<LoadableRouterComponentProps> = {
    redirect: false,
    noMatch: false
  }

  state: LoadableRouterComponentState = {
    renderRouter: false
  }

  componentDidMount() {
    const { meta = {}, history, redirect, location } = this.props

    if (redirect) {
      return
    }

    const to = { ...location, meta }
    from = from || to

    routerBeforeEach(
      to,
      from,
      (...args) => {
        if (args.length === 0) {
          this.setState({ renderRouter: true }, () => {
            from = to
          })
        } else if (args.length === 1) {
          const [param] = args
          if (param === false) {
            history.goBack()
          } else if (typeof param === 'string') {
            history.push(param)
          } else if (typeof param === 'object') {
            const { replace, ...rest } = param
            replace ? history.replace(rest) : history.push(rest)
          }
        } else if (args.length === 2) {
          history.push(args[0], args[1])
        }
      },
      this.context
    )

    // 计算面包屑
    const breadcrumbs = this.calcBreadcrumbs(location.pathname, meta.title)
    ;(this.context as IGlobalContext).dispatch('breadcrumbs', breadcrumbs)
    // console.log('breadcrumbs', breadcrumbs)
  }

  componentDidUpdate() {
    const { meta = {}, location } = this.props
    const to = { ...location, meta }
    from = from || to
    if (this.state.renderRouter) {
      routerAfterEach(to, from, this.context)
    }
  }

  render() {
    const { component, redirect, noMatch, ...others } = this.props
    const { meta = {}, ...rest } = others
    const { authCodes, menus } = this.context as IGlobalContext

    // 执行重定向
    if (redirect) {
      if (typeof redirect === 'string') {
        return <Redirect to={redirect} />
      }
      const firstMenuPath = getFirstPathFromMenus(menus) || routeNames[404]
      return <Redirect to={{ pathname: firstMenuPath }} />
    }

    // 等待路由钩子函数
    if (!this.state.renderRouter) {
      return this.loading
    }

    // 是否noMatch
    if (noMatch) {
      return <NoMathRoute {...this.props} />
    }

    // 校验权限code
    let metaCode: string[] = []
    metaCode = meta.code ? metaCode.concat(meta.code) : metaCode
    if (metaCode.length && authCodes.every((ac) => metaCode.indexOf(ac) === -1) && !skipAuth) {
      return <Redirect to={{ pathname: routeNames[403] }} />
    }

    const Com = meta.sync ? component : loadable(component, { fallback: this.loading })
    return (
      <Context.Provider value={others}>
        <Com {...rest} />
      </Context.Provider>
    )
  }

  loading = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Spin tip="加载中" />
    </div>
  )

  calcBreadcrumbs(pathname: string, title: string = '') {
    const menu = getMenuBreadcrumbs(this.context.menus, pathname)
    let crumbs: RouteBreadCrumb[] = title ? [{ title }] : []
    if (!menu) {
      return crumbs
    }
    const menuCrumbs = menu.parents
      .map((it) => ({ title: it.title, href: (it as any).path } as RouteBreadCrumb))
      .concat([{ title: menu.title, href: menu.path }])
    if (menu.path !== pathname) {
      menuCrumbs.push(...crumbs)
    }
    return menuCrumbs
  }
}

export function useRouter() {
  const context = useContext(Context)
  return context
}
