import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
export default [
  {
    path: routeNames.billhead,
    component: () => import('../../pages/financial/billhead'),
    meta: {
      title: '付款通知单'
      , code: EAuthCode.FINANCIAL_BILLHEAD
    }
  },
  {
    path: routeNames.noticePrint,
    component: () => import('../../pages/financial/noticePrint'),
    meta: {
      title: '通知单打印'
      , hideInMenu: true
      , code: EAuthCode.FINANCIAL_NOTICEPRINT
    }
  },
  {
    path: routeNames.noticeDetail,
    component: () => import('../../pages/financial/noticeDetail'),
    meta: {
      title: '通知单详情'
      , hideInMenu: true
      , code: EAuthCode.FINANCIAL_NOTICEDETAIL
    }
  },
  {
    path: routeNames.backlog,
    component: () => import('../../pages/financial/backlog'),
    meta: {
      title: '财务代办'
      , code: EAuthCode.FINANCIAL_BACKLOG
    }
  }
  // ,
  // {
  //   path: routeNames.contractReview,
  //   component: () => import('../../pages/financial/contractReview'),
  //   meta: {
  //     title: '合同审核'
  //     , hideInMenu: true
  //     // , code: EAuthCode.PARAMSSET_PARAMSMAINTAIN
  //   }
  // }
] as TRoutes[]