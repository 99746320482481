import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
export default [
  {
    path: routeNames.projectInfoList,
    component: () => import('../../pages/projectManagement/projectInfoList'),
    meta: {
      title: '项目信息维护'
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTINFOLIST
    }
  },
  {
    path: routeNames.pinControlChartList,
    component: () => import('../../pages/projectManagement/pinControlChartList'),
    meta: {
      title: '销控图'
      , code: EAuthCode.PROJECTMANAGEMENT_PINCONTROLCHARTLIST
    }
  },
  {
    path: routeNames.pinControlChartDetail,
    component: () => import('../../pages/projectManagement/pinControlChartDetail'),
    meta: {
      title: '销控图详情'
      , hideInMenu: true
      , code: EAuthCode.PROJECTMANAGEMENT_PINCONTROLCHARTDETAIL
    }
  },
  {
    path: routeNames.projectOp,
    component: () => import('../../pages/projectManagement/projectOp'),
    meta: {
      title: '操作项目',
      hideInMenu: true
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTOP
    }
  },
  {
    path: routeNames.projectEdit,
    component: () => import('../../pages/projectManagement/projectEdit'),
    meta: {
      title: '编辑项目',
      hideInMenu: true
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTEDIT
    }
  },
  {
    path: routeNames.projectDetail,
    component: () => import('../../pages/projectManagement/projectDetail'),
    meta: {
      title: '项目详情',
      hideInMenu: true
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTDETAIL
    }
  }

] as TRoutes[]