import { IRequestConfig } from "~/modules/@wmeimob/request/src/types/fetch-type";
import {
  AdminApiAreaDeleteParams,
  AdminApiAreaListGetParams,
  AdminApiBuildingDeleteParams,
  AdminApiBuildingListGetParams,
  AdminApiBusinessDeleteParams,
  AdminApiBusinessDetailListGetParams,
  AdminApiBusinessfollowDeleteParams,
  AdminApiBusinessfollowDetailListGetParams,
  AdminApiBusinessfollowListGetParams,
  AdminApiBusinessListGetParams,
  AdminApiBusinessReportListGetParams,
  AdminApiBusinessupdatelogQueryGetParams,
  AdminApiClientDetailListGetParams,
  AdminApiClientfollowDeleteParams,
  AdminApiClientfollowDetailListGetParams,
  AdminApiClientfollowListGetParams,
  AdminApiClientListGetParams,
  AdminApiClientQueryDetailListGetParams,
  AdminApiClientReportListGetParams,
  AdminApiClientupdatelogListGetParams,
  AdminApiClueDeleteParams,
  AdminApiClueDetailListGetParams,
  AdminApiCluefollowDeleteParams,
  AdminApiCluefollowDetailListGetParams,
  AdminApiCluefollowListGetParams,
  AdminApiClueListGetParams,
  AdminApiClueReportListGetParams,
  AdminApiClueupdatelogQueryGetParams,
  AdminApiCommonCallOnReportListGetParams,
  AdminApiCommonHomePageStatisticsGetParams,
  AdminApiCommonOptionListGetParams,
  AdminApiCommonOptionsBykeyGetParams,
  AdminApiCommonTianyanchaBaseinfoGetParams,
  AdminApiCommonTianyanchaSearchGetParams,
  AdminApiContactsListGetParams,
  AdminApiContractAuditDetailListGetParams,
  AdminApiContractAuditListGetParams,
  AdminApiContractbillsQueryGetParams,
  AdminApiContractDeleteParams,
  AdminApiContractDetailListGetParams,
  AdminApiContractLeaseUnitListGetParams,
  AdminApiContractListGetParams,
  AdminApiContractReportListGetParams,
  AdminApiFloorDeleteParams,
  AdminApiFloorListGetParams,
  AdminApiInvestmentStatisticsListGetParams,
  AdminApiLeasingManagerSignAreaTargetConfigListGetParams,
  AdminApiLeasingManagerSignAreaTargetConfigMonthlyIndicatorDetailReportExportGetParams,
  AdminApiLeasingManagerSignAreaTargetConfigMonthlyIndicatorDetailReportListGetParams,
  AdminApiMallConfChannelDeleteDeleteParams,
  AdminApiMallConfChannelQueryGetParams,
  AdminApiMallConfHotKeyDeleteDeleteParams,
  AdminApiMallConfHotKeyQueryGetParams,
  AdminApiMallConfigControllerQueryByKeyGetParams,
  AdminApiMallConfMaterialDeleteDeleteParams,
  AdminApiMallConfMaterialGroupDeleteDeleteParams,
  AdminApiMallConfMaterialGroupQueryAllGetParams,
  AdminApiMallConfMaterialQueryGetParams,
  AdminApiMallConfPageDeleteDeleteParams,
  AdminApiMallConfPageDetailGetParams,
  AdminApiMallConfRefundReasonDeleteDeleteParams,
  AdminApiMallGoodsCategoryDelDeleteParams,
  AdminApiMallGoodsCategoryGetOneGetParams,
  AdminApiMallGoodsCategoryGetPageGetParams,
  AdminApiMallGoodsCategoryShowPutParams,
  AdminApiMallGoodsCategorySortPutParams,
  AdminApiMessageListGetParams,
  AdminApiMessageReadPostParams,
  AdminApiMessageTestGetParams,
  AdminApiPaymentAdviceListGetParams,
  AdminApiPaymentAdviceReportListGetParams,
  AdminApiPhaseDeleteParams,
  AdminApiPhaseListGetParams,
  AdminApiProjectDeleteParams,
  AdminApiProjectDetailListGetParams,
  AdminApiProjectListGetParams,
  AdminApiProjectPinControlChartAreaGetParams,
  AdminApiProjectPinControlChartDataGetParams,
  AdminApiProjectPinControlChartMoneyGetParams,
  AdminApiProjectPinControlDetailDataGetParams,
  AdminApiProjectPinControlMoneyChartClientGetParams,
  AdminApiProjectPinControlMoneyChartMonthGetParams,
  AdminApiProjectQueryDetailListGetParams,
  AdminApiProjectSalesControlDetailListGetParams,
  AdminApiProjectSalesControlListGetParams,
  AdminApiProjectupdatelogListGetParams,
  AdminApiSysDeptDeleteDeleteParams,
  AdminApiSysDeptQueryGetParams,
  AdminApiSysResourceButtonsAndApisBatchGetParams,
  AdminApiSysResourceGetParams,
  AdminApiSysResourceTreeGetParams,
  AdminApiSysRoleDataPermissionListGetParams,
  AdminApiSysRoleDeleteDeleteParams,
  AdminApiSysRoleQueryAllGetParams,
  AdminApiSysRoleQueryGetParams,
  AdminApiSysUserDeleteDeleteParams,
  AdminApiSysUserQueryGetParams,
  AdminApiSysUserResetDefaultDeleteParams,
  AdminApiUnitDeleteParams,
  AdminApiUnitListGetParams,
  AreaDto,
  BuildingDto,
  BusinessDto,
  BusinessFollowDto,
  BusinessUpdateLog,
  ClientDto,
  ClientFollowDto,
  ClueDto,
  ClueFollowDto,
  ClueUpdateLog,
  ContactsDto,
  ContractBills,
  ContractDto,
  ContractLeaseUnit,
  DdDto,
  FloorDto,
  JsonResultAddResult,
  JsonResultBoolean,
  JsonResultBusinessUpdateLog,
  JsonResultButtonAndApiVo,
  JsonResultClueUpdateLog,
  JsonResultContractBills,
  JsonResultContractLeaseUnit,
  JsonResultCurrentUser,
  JsonResultDdExtend,
  JsonResultHomePageStatistics,
  JsonResultInt,
  JsonResultListDataPermission,
  JsonResultListDdExtend,
  JsonResultListLeasingManagerVO,
  JsonResultListMallConfConfigDto,
  JsonResultListMallConfMaterialGroupVo,
  JsonResultListMallConfPageVo,
  JsonResultListMallConfRefundReasonDto,
  JsonResultListMallGoodsCategoryVo,
  JsonResultListMenuDataVo,
  JsonResultListMonthlyIndicatorDetailReportVO,
  JsonResultListPinContrlChartMoneyClientDto,
  JsonResultListSysDeptHaveUserVo,
  JsonResultListSysDeptVo,
  JsonResultListSysResource,
  JsonResultListSysRole,
  JsonResultListSysRoleDto,
  JsonResultListTreeResourceVo,
  JsonResultLong,
  JsonResultMallConfPageVo,
  JsonResultMallGoodsCategoryVo,
  JsonResultMapStringObject,
  JsonResultObject,
  JsonResultPageInfoArea,
  JsonResultPageInfoBuilding,
  JsonResultPageInfoBusinessExtend,
  JsonResultPageInfoBusinessFollow,
  JsonResultPageInfoBusinessFollowExtend,
  JsonResultPageInfoBusinessUpdateLog,
  JsonResultPageInfoCallOnDetail,
  JsonResultPageInfoClient,
  JsonResultPageInfoClientExtend,
  JsonResultPageInfoClientFollow,
  JsonResultPageInfoClientFollowExtend,
  JsonResultPageInfoClientUpdateLog,
  JsonResultPageInfoClue,
  JsonResultPageInfoClueExtend,
  JsonResultPageInfoClueFollow,
  JsonResultPageInfoClueFollowExtend,
  JsonResultPageInfoClueUpdateLog,
  JsonResultPageInfoContacts,
  JsonResultPageInfoContractBills,
  JsonResultPageInfoContractExtend,
  JsonResultPageInfoContractLeaseUnit,
  JsonResultPageInfoDdExtend,
  JsonResultPageInfoFloor,
  JsonResultPageInfoInvestmentStatisticsVO,
  JsonResultPageInfoLeasingManagerSignAreaTargetConfig,
  JsonResultPageInfoMallConfChannelDto,
  JsonResultPageInfoMallConfHotKeyDto,
  JsonResultPageInfoMallConfMaterialVo,
  JsonResultPageInfoMallConfPageVo,
  JsonResultPageInfoMessage,
  JsonResultPageInfoMonthlyIndicatorDetailReportVO,
  JsonResultPageInfoPaymentAdviceExtend,
  JsonResultPageInfoPhase,
  JsonResultPageInfoProjectExtend,
  JsonResultPageInfoProjectUpdateLogExtend,
  JsonResultPageInfoSysDeptVo,
  JsonResultPageInfoSysResource,
  JsonResultPageInfoSysRoleDataPermissionExtend,
  JsonResultPageInfoSysRoleDto,
  JsonResultPageInfoSysUserVo,
  JsonResultPageInfoUnit,
  JsonResultPinContrlChartAreaVO,
  JsonResultPinContrlChartDataVO,
  JsonResultPinContrlChartMoneyVO,
  JsonResultString,
  JsonResultSysAuthVo,
  JsonResultSysResource,
  JsonResultSysRoleVo,
  JsonResultVoid,
  LeasingManagerSignAreaTargetConfigDto,
  ListDTOProjectDto,
  LoginDto,
  MallConfChannelAddParam,
  MallConfHotKeyAddParam,
  MallConfMaterialAddParam,
  MallConfMaterialGroupAddParam,
  MallConfMaterialMoveParam,
  MallConfPageAddParam,
  MallConfRefundReasonAddParam,
  MallGoodsCategoryParam,
  MenuDragDto,
  PaymentAdviceDto,
  PhaseDto,
  PhaseFloorDto,
  ProjectDto,
  RegisterDto,
  ResetPwdParam,
  RoleResourceSaveDto,
  SysDeptAddParam,
  SysResource,
  SysRoleAddParam,
  SysRoleDataPermissionDto,
  SysUserAddParam,
  UnitDto,
  UserRolesDto
} from "./data-contracts";
import requestInstance from "./instance";

type RequestConfig = Omit<IRequestConfig, "url" | "method">;

export const API = {
  /**
   * No description
   * @name POST /admin/api/mallConfPage/add
   * @summary 自定义页面新增
   * @tags 自定义页面
   * @response `200` `JsonResultLong` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfPage/add_POST": (data: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name GET /admin/api/mallConfigController/base/order
   * @summary 基础设置-订单设置、售后流程、评论设置查询              返回参数：order_payment_waiting_time_hours, X小时未支付自动关闭订单            返回参数：order_receiving_waiting_time_day,发货后自动收货时间            返回参数：order_receiving_close,已收货自动关闭时间            返回参数：refund_only_money_auto_agree_day, *买家发起仅退款申请：
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfigController/base/order_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfigController/base/order`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name PUT /admin/api/sysUser/resetPwd
   * @summary 重置密码
   * @tags 系统-用户管理
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/resetPwd_PUT": (data: ResetPwdParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/resetPwd`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysRole/query
   * @summary 角色表分页查询
   * @tags 系统-角色管理
   * @response `200` `JsonResultPageInfoSysRoleDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRole/query_GET": (query: AdminApiSysRoleQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysRoleDto>,
  /**
   * No description
   * @name POST /admin/api/mallConfChannel/add
   * @summary 渠道新增
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfChannel/add_POST": (data: MallConfChannelAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfChannel/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/phase
   * @summary 分期 删除
   * @tags 分期
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/phase_DELETE": (query: AdminApiPhaseDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/phase`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/phase
   * @summary 分期 新增
   * @tags 分期
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/phase_POST": (data: PhaseDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/phase`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/phase
   * @summary 分期 修改
   * @tags 分期
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/phase_PUT": (data: PhaseDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/phase`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/cluefollow/list
   * @summary 线索跟进表(拜访记录) 分页查询
   * @tags 线索跟进表(拜访记录)
   * @response `200` `JsonResultPageInfoClueFollow` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/cluefollow/list_GET": (query: AdminApiCluefollowListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cluefollow/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClueFollow>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/queryAll
   * @summary 自定义页面不分页查询
   * @tags 自定义页面
   * @response `200` `JsonResultListMallConfPageVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfPage/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfPageVo>,
  /**
   * No description
   * @name DELETE /admin/api/clue
   * @summary 线索 删除
   * @tags 线索表
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/clue_DELETE": (query: AdminApiClueDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/clue
   * @summary 线索 修改
   * @tags 线索表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clue_PUT": (data: ClueDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/clue
   * @summary 线索 新增
   * @tags 线索表
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clue_POST": (data: ClueDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/mallConfPage/update
   * @summary 自定义页面修改
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfPage/update_PUT": (data: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/sysResource/{id}
   * @summary 修改
   * @tags 系统-资源管理
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource/{id}_PUT": (id: number, data: SysResource, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource/${id}`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/sysResource/{id}
   * @summary 详情
   * @tags 系统-资源管理
   * @response `200` `JsonResultSysResource` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultSysResource>,
  /**
   * No description
   * @name DELETE /admin/api/sysResource/{id}
   * @summary 删除
   * @tags 系统-资源管理
   * @response `200` `JsonResultString` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/sysResource/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/leasingManagerSignAreaTargetConfig/import
   * @summary 招商经理签约面积指标配置 导入
   * @tags 招商经理签约面积指标配置（年份）
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/leasingManagerSignAreaTargetConfig/import_POST": (data: object, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/leasingManagerSignAreaTargetConfig/import`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name POST /admin/api/contract
   * @summary 合同 新增
   * @tags 合同
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract_POST": (data: ContractDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name DELETE /admin/api/contract
   * @summary 合同 删除
   * @tags 合同
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/contract_DELETE": (query: AdminApiContractDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/contract
   * @summary 合同 修改
   * @tags 合同
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract_PUT": (data: ContractDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/contractLeaseUnit
   * @summary 修改
   * @tags 租赁单元表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractLeaseUnit_PUT": (data: ContractLeaseUnit, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractLeaseUnit`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/contractLeaseUnit
   * @summary 新增
   * @tags 租赁单元表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractLeaseUnit_POST": (data: ContractLeaseUnit, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractLeaseUnit`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/query
   * @summary 自定义页面分页查询
   * @tags 自定义页面
   * @response `200` `JsonResultPageInfoMallConfPageVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfPage/query_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/query`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfPageVo>,
  /**
   * No description
   * @name GET /admin/api/project/pinControlChart/data
   * @summary 销控图表-统计数据
   * @tags 项目表
   * @response `200` `JsonResultPinContrlChartDataVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/pinControlChart/data_GET": (
    query: AdminApiProjectPinControlChartDataGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/pinControlChart/data`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPinContrlChartDataVO>,
  /**
   * No description
   * @name GET /admin/api/common/options
   * @summary 获取字典列表
   * @tags 通用
   * @response `200` `JsonResultListDdExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/options_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/common/options`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListDdExtend>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfMaterial/delete
   * @summary 素材库删除
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/mallConfMaterial/delete_DELETE": (
    query: AdminApiMallConfMaterialDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfMaterial/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysUser/add
   * @summary 用户新增(返回ID)
   * @tags 系统-用户管理
   * @response `200` `JsonResultLong` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/add_POST": (data: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name PUT /admin/api/contractbills
   * @summary 修改
   * @tags 合同账单表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractbills_PUT": (data: ContractBills, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractbills`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/contractbills
   * @summary 新增
   * @tags 合同账单表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractbills_POST": (data: ContractBills, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractbills`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/sysUser/{id}/roles
   * @summary 根据用户ID查询用户关联的角色集合
   * @tags 系统-用户管理
   * @response `200` `JsonResultListSysRole` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/{id}/roles_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/${id}/roles`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultListSysRole>,
  /**
   * No description
   * @name GET /admin/api/common/CallOn/reportList
   * @summary 拜访 明细报表
   * @tags 通用
   * @response `200` `JsonResultPageInfoCallOnDetail` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/CallOn/reportList_GET": (
    query: AdminApiCommonCallOnReportListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/common/CallOn/reportList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoCallOnDetail>,
  /**
   * No description
   * @name GET /admin/api/project/salesControlList
   * @summary 销控图 列表
   * @tags 项目表
   * @response `200` `JsonResultPageInfoProjectExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/salesControlList_GET": (
    query: AdminApiProjectSalesControlListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/salesControlList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjectExtend>,
  /**
   * No description
   * @name PUT /admin/api/sysResource
   * @summary 修改
   * @tags 系统-资源管理
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource_PUT": (data: SysResource, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/sysResource
   * @summary 搜索资源
   * @tags 系统-资源管理
   * @response `200` `JsonResultPageInfoSysResource` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource_GET": (query: AdminApiSysResourceGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysResource>,
  /**
   * No description
   * @name POST /admin/api/sysResource
   * @summary 新增
   * @tags 系统-资源管理
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource_POST": (data: SysResource, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/common/updateOptionList
   * @summary 修改 列表项List
   * @tags 通用
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/updateOptionList_PUT": (data: DdDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/common/updateOptionList`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/contract/auditList
   * @summary 合同审核 分页查询
   * @tags 合同
   * @response `200` `JsonResultPageInfoContractExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/auditList_GET": (query: AdminApiContractAuditListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/auditList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContractExtend>,
  /**
   * No description
   * @name DELETE /admin/api/sysDept/delete
   * @summary 部门删除
   * @tags 系统-组织机构管理
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/sysDept/delete_DELETE": (query: AdminApiSysDeptDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysDept/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/phase/list
   * @summary 分期 分页查询
   * @tags 分期
   * @response `200` `JsonResultPageInfoPhase` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/phase/list_GET": (query: AdminApiPhaseListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/phase/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoPhase>,
  /**
   * No description
   * @name DELETE /admin/api/contractLeaseUnit/{id}
   * @summary 删除
   * @tags 租赁单元表
   * @response `200` `JsonResultString` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/contractLeaseUnit/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractLeaseUnit/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/contractLeaseUnit/{id}
   * @summary 详情
   * @tags 租赁单元表
   * @response `200` `JsonResultContractLeaseUnit` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractLeaseUnit/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractLeaseUnit/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultContractLeaseUnit>,
  /**
   * No description
   * @name PUT /admin/api/contractLeaseUnit/{id}
   * @summary 修改
   * @tags 租赁单元表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractLeaseUnit/{id}_PUT": (id: number, data: ContractLeaseUnit, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractLeaseUnit/${id}`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/detail
   * @summary 自定义页面内容详情
   * @tags 自定义页面
   * @response `200` `JsonResultMallConfPageVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfPage/detail_GET": (query: AdminApiMallConfPageDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfPageVo>,
  /**
   * No description
   * @name POST /admin/api/business
   * @summary 商机 新增
   * @tags 商机表
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/business_POST": (data: BusinessDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/business
   * @summary 商机 修改
   * @tags 商机表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/business_PUT": (data: BusinessDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name DELETE /admin/api/business
   * @summary 商机 删除
   * @tags 商机表
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/business_DELETE": (query: AdminApiBusinessDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name DELETE /admin/api/cluefollow
   * @summary 线索跟进表(拜访记录) 删除
   * @tags 线索跟进表(拜访记录)
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/cluefollow_DELETE": (query: AdminApiCluefollowDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cluefollow`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/cluefollow
   * @summary 线索跟进表(拜访记录) 修改
   * @tags 线索跟进表(拜访记录)
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/cluefollow_PUT": (data: ClueFollowDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cluefollow`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/cluefollow
   * @summary 线索跟进表(拜访记录) 新增
   * @tags 线索跟进表(拜访记录)
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/cluefollow_POST": (data: ClueFollowDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cluefollow`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name GET /admin/api/project/salesControlDetailList
   * @summary 销控图 详情列表
   * @tags 项目表
   * @response `200` `JsonResultPageInfoProjectExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/salesControlDetailList_GET": (
    query: AdminApiProjectSalesControlDetailListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/salesControlDetailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjectExtend>,
  /**
   * No description
   * @name PUT /admin/api/sysRole/update
   * @summary 修改角色
   * @tags 系统-角色管理
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRole/update_PUT": (data: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysUser/{id}/resources
   * @summary 根据用户ID获取用户可访问的资源
   * @tags 系统-用户管理
   * @response `200` `JsonResultListSysResource` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/{id}/resources_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/${id}/resources`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultListSysResource>,
  /**
   * No description
   * @name POST /admin/api/mallConfMaterialGroup/add
   * @summary 素材库分组新增
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfMaterialGroup/add_POST": (data: MallConfMaterialGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfMaterialGroup/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/businessfollow
   * @summary 商机跟进(拜访记录) 删除
   * @tags 商机跟进表(拜访记录)
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/businessfollow_DELETE": (query: AdminApiBusinessfollowDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessfollow`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/businessfollow
   * @summary 商机跟进(拜访记录) 新增
   * @tags 商机跟进表(拜访记录)
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessfollow_POST": (data: BusinessFollowDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessfollow`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/businessfollow
   * @summary 商机跟进(拜访记录) 修改
   * @tags 商机跟进表(拜访记录)
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessfollow_PUT": (data: BusinessFollowDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessfollow`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/mallConfMaterial/add
   * @summary 素材库新增
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfMaterial/add_POST": (data: MallConfMaterialAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfMaterial/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfHotKey/delete
   * @summary delete
   * @tags 热门搜索
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/mallConfHotKey/delete_DELETE": (
    query: AdminApiMallConfHotKeyDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfHotKey/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/project/list
   * @summary 项目 列表 分页查询
   * @tags 项目表
   * @response `200` `JsonResultPageInfoProjectExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/list_GET": (query: AdminApiProjectListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/project/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjectExtend>,
  /**
   * No description
   * @name POST /admin/public/forgot
   * @summary 忘记密码
   * @tags 公开访问的APIs
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/public/forgot_POST": (data: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/public/forgot`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/project/detailList
   * @summary 项目 详情列表
   * @tags 项目表
   * @response `200` `JsonResultPageInfoProjectExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/detailList_GET": (query: AdminApiProjectDetailListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/project/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjectExtend>,
  /**
   * No description
   * @name GET /admin/api/sysResource/buttons-and-apis-batch
   * @summary 根据菜单ID加载菜单下所有的接口资源和按钮资源（批量）
   * @tags 系统-资源管理
   * @response `200` `JsonResultListMenuDataVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource/buttons-and-apis-batch_GET": (
    query: AdminApiSysResourceButtonsAndApisBatchGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysResource/buttons-and-apis-batch`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMenuDataVo>,
  /**
   * No description
   * @name PUT /admin/api/cluefollow/updateClueStatus
   * @summary 线索跟进 线索状态 修改
   * @tags 线索跟进表(拜访记录)
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/cluefollow/updateClueStatus_PUT": (data: ClueFollowDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cluefollow/updateClueStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/paymentAdvice/list
   * @summary 付款通知单 列表
   * @tags 付款通知单
   * @response `200` `JsonResultPageInfoPaymentAdviceExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/paymentAdvice/list_GET": (query: AdminApiPaymentAdviceListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paymentAdvice/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoPaymentAdviceExtend>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/delete
   * @summary 用户删除
   * @tags 系统-用户管理
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/sysUser/delete_DELETE": (query: AdminApiSysUserDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/unit/list
   * @summary 单元 分页查询
   * @tags 单元
   * @response `200` `JsonResultPageInfoUnit` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/unit/list_GET": (query: AdminApiUnitListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/unit/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoUnit>,
  /**
   * No description
   * @name GET /admin/api/business/list
   * @summary 商机 列表 分页查询
   * @tags 商机表
   * @response `200` `JsonResultPageInfoBusinessExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/business/list_GET": (query: AdminApiBusinessListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBusinessExtend>,
  /**
   * No description
   * @name PUT /admin/api/mallGoodsCategory/sort
   * @summary 修改展示顺序 越大 越靠前
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallGoodsCategory/sort_PUT": (
    query: AdminApiMallGoodsCategorySortPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallGoodsCategory/sort`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/api/floor
   * @summary 楼层 删除
   * @tags 楼层
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/floor_DELETE": (query: AdminApiFloorDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/floor`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/floor
   * @summary 楼层 修改
   * @tags 楼层
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/floor_PUT": (data: FloorDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/floor`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/floor
   * @summary 楼层 新增
   * @tags 楼层
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/floor_POST": (data: FloorDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/floor`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name GET /admin/api/leasingManagerSignAreaTargetConfig/leasingManager/export
   * @summary 招商经理角色的用户列表 导出（对应于前端的下载模板功能）
   * @tags 招商经理签约面积指标配置（年份）
   * @response `200` `JsonResultListLeasingManagerVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/leasingManagerSignAreaTargetConfig/leasingManager/export_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/leasingManagerSignAreaTargetConfig/leasingManager/export`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListLeasingManagerVO>,
  /**
   * No description
   * @name GET /admin/api/mallConfigController/queryByKey
   * @summary 根据key查询
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultString` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfigController/queryByKey_GET": (
    query: AdminApiMallConfigControllerQueryByKeyGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfigController/queryByKey`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/sysUser/LoginInfo
   * @summary 获取登录用户信息
   * @tags 系统-用户管理
   * @response `200` `JsonResultCurrentUser` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/LoginInfo_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/LoginInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultCurrentUser>,
  /**
   * No description
   * @name PUT /admin/api/client/batchDistribute
   * @summary 客户 批量分配归属人
   * @tags 客户
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/client/batchDistribute_PUT": (data: ClientDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/client/batchDistribute`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/clientfollow
   * @summary 客户跟进表(拜访记录) 新增
   * @tags 客户跟进表(拜访记录)
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clientfollow_POST": (data: ClientFollowDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clientfollow`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name DELETE /admin/api/clientfollow
   * @summary 客户跟进表(拜访记录) 删除
   * @tags 客户跟进表(拜访记录)
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/clientfollow_DELETE": (query: AdminApiClientfollowDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clientfollow`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/clientfollow
   * @summary 客户跟进表(拜访记录) 修改
   * @tags 客户跟进表(拜访记录)
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clientfollow_PUT": (data: ClientFollowDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clientfollow`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/client/queryDetailList
   * @summary 客户 详情列表
   * @tags 客户
   * @response `200` `JsonResultPageInfoClient` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/client/queryDetailList_GET": (
    query: AdminApiClientQueryDetailListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/client/queryDetailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClient>,
  /**
   * No description
   * @name GET /admin/api/sysUser/current-authorities
   * @summary 获取当前用户权限
   * @tags 系统-用户管理
   * @response `200` `JsonResultSysAuthVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/current-authorities_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/current-authorities`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultSysAuthVo>,
  /**
   * No description
   * @name POST /admin/api/mallConfHotKey/add
   * @summary add
   * @tags 热门搜索
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfHotKey/add_POST": (data: MallConfHotKeyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfHotKey/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysDept/queryAll
   * @summary 部门表不分页查询
   * @tags 系统-组织机构管理
   * @response `200` `JsonResultListSysDeptVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysDept/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysDept/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptVo>,
  /**
   * No description
   * @name POST /admin/api/sysDept/add
   * @summary 部门新增(返回ID)
   * @tags 系统-组织机构管理
   * @response `200` `JsonResultLong` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysDept/add_POST": (data: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysDept/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name POST /admin/api/sysRole/resources-role
   * @summary 批量保存角色和资源的对应关系
   * @tags 系统-角色管理
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRole/resources-role_POST": (data: RoleResourceSaveDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/resources-role`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/clue/detailList
   * @summary 线索 详情列表
   * @tags 线索表
   * @response `200` `JsonResultPageInfoClueExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clue/detailList_GET": (query: AdminApiClueDetailListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClueExtend>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfChannel/delete
   * @summary 渠道删除
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/mallConfChannel/delete_DELETE": (
    query: AdminApiMallConfChannelDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfChannel/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/mallConfigController/insertAndUpdate
   * @summary 通用设置保存与修改 {key : value} 形式
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfigController/insertAndUpdate_POST": (data: Record<string, string>, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfigController/insertAndUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/leasingManagerSignAreaTargetConfig/monthlyIndicatorDetailReport/list
   * @summary 季度指标明细报表 列表 分页查询
   * @tags 招商经理签约面积指标配置（年份）
   * @response `200` `JsonResultPageInfoMonthlyIndicatorDetailReportVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/leasingManagerSignAreaTargetConfig/monthlyIndicatorDetailReport/list_GET": (
    query: AdminApiLeasingManagerSignAreaTargetConfigMonthlyIndicatorDetailReportListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/leasingManagerSignAreaTargetConfig/monthlyIndicatorDetailReport/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMonthlyIndicatorDetailReportVO>,
  /**
   * No description
   * @name GET /admin/api/sysDept/queryAllHaveUser
   * @summary 部门表不分页查询（包含员工信息）
   * @tags 系统-组织机构管理
   * @response `200` `JsonResultListSysDeptHaveUserVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysDept/queryAllHaveUser_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysDept/queryAllHaveUser`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptHaveUserVo>,
  /**
   * No description
   * @name POST /admin/api/leasingManagerSignAreaTargetConfig
   * @summary 招商经理签约面积指标配置 保存
   * @tags 招商经理签约面积指标配置（年份）
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/leasingManagerSignAreaTargetConfig_POST": (
    data: LeasingManagerSignAreaTargetConfigDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/leasingManagerSignAreaTargetConfig`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name GET /admin/api/mallConfRefundReason/queryAll
   * @summary 售后原因不分页查询
   * @tags 售后原因
   * @response `200` `JsonResultListMallConfRefundReasonDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfRefundReason/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfRefundReason/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfRefundReasonDto>,
  /**
   * No description
   * @name DELETE /admin/api/mallGoodsCategory/del
   * @summary 类目删除 级联删除
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/mallGoodsCategory/del_DELETE": (
    query: AdminApiMallGoodsCategoryDelDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallGoodsCategory/del`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/api/business/batchDistribute
   * @summary 商机 批量分配归属人
   * @tags 商机表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/business/batchDistribute_PUT": (data: BusinessDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business/batchDistribute`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/message/test
   * @summary 测试
   * @tags 站内信接口
   * @response `200` `object` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/message/test_GET": (query: AdminApiMessageTestGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/message/test`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<object>,
  /**
   * No description
   * @name GET /admin/api/floor/list
   * @summary 楼层 分页查询
   * @tags 楼层
   * @response `200` `JsonResultPageInfoFloor` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/floor/list_GET": (query: AdminApiFloorListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/floor/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoFloor>,
  /**
   * No description
   * @name PUT /admin/api/contract/batchDistributeAudit
   * @summary 合同 批量分配审核人
   * @tags 合同
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/batchDistributeAudit_PUT": (data: ContractDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/batchDistributeAudit`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/businessupdatelog/{id}
   * @summary 详情
   * @tags 商机信息更新日志表
   * @response `200` `JsonResultBusinessUpdateLog` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessupdatelog/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessupdatelog/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultBusinessUpdateLog>,
  /**
   * No description
   * @name DELETE /admin/api/businessupdatelog/{id}
   * @summary 删除
   * @tags 商机信息更新日志表
   * @response `200` `JsonResultString` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/businessupdatelog/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessupdatelog/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/businessupdatelog/{id}
   * @summary 修改
   * @tags 商机信息更新日志表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessupdatelog/{id}_PUT": (id: number, data: BusinessUpdateLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessupdatelog/${id}`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/unit
   * @summary 单元 修改
   * @tags 单元
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/unit_PUT": (data: UnitDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/unit`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/unit
   * @summary 单元 新增
   * @tags 单元
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/unit_POST": (data: UnitDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/unit`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name DELETE /admin/api/unit
   * @summary 单元 删除
   * @tags 单元
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/unit_DELETE": (query: AdminApiUnitDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/unit`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/mallConfChannel/update
   * @summary 渠道修改
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfChannel/update_PUT": (data: MallConfChannelAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfChannel/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/cluefollow/detailList
   * @summary 线索跟进表(拜访记录)详情 分页查询
   * @tags 线索跟进表(拜访记录)
   * @response `200` `JsonResultPageInfoClueFollowExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/cluefollow/detailList_GET": (query: AdminApiCluefollowDetailListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cluefollow/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClueFollowExtend>,
  /**
   * No description
   * @name GET /admin/api/clientfollow/list
   * @summary 客户跟进(拜访记录) 分页查询
   * @tags 客户跟进表(拜访记录)
   * @response `200` `JsonResultPageInfoClientFollow` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clientfollow/list_GET": (query: AdminApiClientfollowListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clientfollow/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClientFollow>,
  /**
   * No description
   * @name GET /admin/api/project/pinControl/detail/data
   * @summary 销控图表详情-租金物业收缴率
   * @tags 项目表
   * @response `200` `JsonResultPinContrlChartDataVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/pinControl/detail/data_GET": (
    query: AdminApiProjectPinControlDetailDataGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/pinControl/detail/data`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPinContrlChartDataVO>,
  /**
   * No description
   * @name PUT /admin/api/sysDept/update
   * @summary 部门修改
   * @tags 系统-组织机构管理
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysDept/update_PUT": (data: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysDept/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/clientupdatelog/list
   * @summary 客户信息更新日志 分页查询
   * @tags 客户信息更新日志
   * @response `200` `JsonResultPageInfoClientUpdateLog` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clientupdatelog/list_GET": (query: AdminApiClientupdatelogListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clientupdatelog/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClientUpdateLog>,
  /**
   * No description
   * @name GET /admin/api/contract/detailList
   * @summary 合同详情 列表
   * @tags 合同
   * @response `200` `JsonResultPageInfoContractExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/detailList_GET": (query: AdminApiContractDetailListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContractExtend>,
  /**
   * No description
   * @name PUT /admin/api/mallConfHotKey/update
   * @summary updateAdmin
   * @tags 热门搜索
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfHotKey/update_PUT": (data: MallConfHotKeyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfHotKey/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/mallConfMaterial/update
   * @summary 素材库修改
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfMaterial/update_PUT": (data: MallConfMaterialAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfMaterial/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mallConfHotKey/query
   * @summary 热词分页查询
   * @tags 热门搜索
   * @response `200` `JsonResultPageInfoMallConfHotKeyDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfHotKey/query_GET": (query: AdminApiMallConfHotKeyQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfHotKey/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfHotKeyDto>,
  /**
   * No description
   * @name POST /admin/api/sysRoleDataPermission
   * @summary 角色数据权限 新增
   * @tags 角色数据权限
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRoleDataPermission_POST": (data: SysRoleDataPermissionDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRoleDataPermission`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/sysRoleDataPermission
   * @summary 角色数据权限 编辑
   * @tags 角色数据权限
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRoleDataPermission_PUT": (data: SysRoleDataPermissionDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRoleDataPermission`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/contract/list
   * @summary 合同 分页查询
   * @tags 合同
   * @response `200` `JsonResultPageInfoContractExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/list_GET": (query: AdminApiContractListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContractExtend>,
  /**
   * No description
   * @name GET /admin/api/mallConfigController/base/themeConfig
   * @summary 基础设置-平台主题 （通用设置接口可修改）             返回参数：theme_config, 默认主题肤色   1 / 2 / 3 / 4
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfigController/base/themeConfig_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfigController/base/themeConfig`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name POST /admin/public/register
   * @summary 注册（返回token）
   * @tags 公开访问的APIs
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/public/register_POST": (data: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/public/register`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/sysUser/query
   * @summary 用户表分页查询
   * @tags 系统-用户管理
   * @response `200` `JsonResultPageInfoSysUserVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/query_GET": (query: AdminApiSysUserQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysUserVo>,
  /**
   * No description
   * @name PUT /admin/api/contract/batchDistribute
   * @summary 合同 批量分配归属人
   * @tags 合同
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/batchDistribute_PUT": (data: ContractDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/batchDistribute`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/area/list
   * @summary 区域 分页查询
   * @tags 区域表
   * @response `200` `JsonResultPageInfoArea` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/area/list_GET": (query: AdminApiAreaListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/area/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoArea>,
  /**
   * No description
   * @name POST /admin/api/businessupdatelog
   * @summary 新增
   * @tags 商机信息更新日志表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessupdatelog_POST": (data: BusinessUpdateLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessupdatelog`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/businessupdatelog
   * @summary 修改
   * @tags 商机信息更新日志表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessupdatelog_PUT": (data: BusinessUpdateLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessupdatelog`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/project/pinControl/moneyChart/month
   * @summary 销控图表详情-租金、物业费收缴情况-月度回款情况
   * @tags 项目表
   * @response `200` `JsonResultListPinContrlChartMoneyClientDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/pinControl/moneyChart/month_GET": (
    query: AdminApiProjectPinControlMoneyChartMonthGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/pinControl/moneyChart/month`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListPinContrlChartMoneyClientDto>,
  /**
   * No description
   * @name POST /admin/api/sysUser/roles-user
   * @summary 保存用户与角色的对应关系
   * @tags 系统-用户管理
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/roles-user_POST": (data: UserRolesDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/roles-user`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/mallConfRefundReason/update
   * @summary updateAdmin
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfRefundReason/update_PUT": (data: MallConfRefundReasonAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfRefundReason/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/clueupdatelog
   * @summary 新增
   * @tags 线索信息更新日志表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clueupdatelog_POST": (data: ClueUpdateLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clueupdatelog`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/clueupdatelog
   * @summary 修改
   * @tags 线索信息更新日志表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clueupdatelog_PUT": (data: ClueUpdateLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clueupdatelog`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/common/manualTask
   * @summary manualTask
   * @tags 通用
   * @response `200` `JsonResultVoid` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/manualTask_GET": (data: Record<string, object>, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/common/manualTask`,
      method: "GET",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/mallGoodsCategory/show
   * @summary 是否展示
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallGoodsCategory/show_PUT": (
    query: AdminApiMallGoodsCategoryShowPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallGoodsCategory/show`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/api/common/homePageStatistics
   * @summary 招商首页 统计数据
   * @tags 通用
   * @response `200` `JsonResultHomePageStatistics` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/homePageStatistics_GET": (
    query: AdminApiCommonHomePageStatisticsGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/common/homePageStatistics`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultHomePageStatistics>,
  /**
   * No description
   * @name GET /admin/api/businessfollow/list
   * @summary 商机跟进表(拜访记录) 分页查询
   * @tags 商机跟进表(拜访记录)
   * @response `200` `JsonResultPageInfoBusinessFollow` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessfollow/list_GET": (query: AdminApiBusinessfollowListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/businessfollow/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBusinessFollow>,
  /**
   * No description
   * @name GET /admin/api/clue/reportList
   * @summary 线索 明细报表
   * @tags 线索表
   * @response `200` `JsonResultPageInfoClueExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clue/reportList_GET": (query: AdminApiClueReportListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue/reportList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClueExtend>,
  /**
   * No description
   * @name POST /admin/api/floor/phaseAddFloor
   * @summary 分期中添加楼层
   * @tags 楼层
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/floor/phaseAddFloor_POST": (data: PhaseFloorDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/floor/phaseAddFloor`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name GET /admin/api/clueupdatelog/{id}
   * @summary 详情
   * @tags 线索信息更新日志表
   * @response `200` `JsonResultClueUpdateLog` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clueupdatelog/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clueupdatelog/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultClueUpdateLog>,
  /**
   * No description
   * @name PUT /admin/api/clueupdatelog/{id}
   * @summary 修改
   * @tags 线索信息更新日志表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clueupdatelog/{id}_PUT": (id: number, data: ClueUpdateLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clueupdatelog/${id}`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/clueupdatelog/{id}
   * @summary 删除
   * @tags 线索信息更新日志表
   * @response `200` `JsonResultString` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/clueupdatelog/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clueupdatelog/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/client/detailList
   * @summary 客户 详情列表
   * @tags 客户
   * @response `200` `JsonResultPageInfoClientExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/client/detailList_GET": (query: AdminApiClientDetailListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/client/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClientExtend>,
  /**
   * No description
   * @name GET /admin/api/businessupdatelog/query
   * @summary 商机信息更新日志表分页查询
   * @tags 商机信息更新日志表
   * @response `200` `JsonResultPageInfoBusinessUpdateLog` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessupdatelog/query_GET": (
    query: AdminApiBusinessupdatelogQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/businessupdatelog/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBusinessUpdateLog>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/resetDefault
   * @summary 重置密码
   * @tags 系统-用户管理
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/sysUser/resetDefault_DELETE": (
    query: AdminApiSysUserResetDefaultDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysUser/resetDefault`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 默认账号:19999999999 密码ocj123456 密码需要aes加密，获取token后放在header中{Authorization:你的token}
   * @name POST /admin/public/login
   * @summary 登录
   * @tags 公开访问的APIs
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/public/login_POST": (data: LoginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/public/login`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/contract/auditDetailList
   * @summary 合同审核详情 列表
   * @tags 合同
   * @response `200` `JsonResultPageInfoContractExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/auditDetailList_GET": (
    query: AdminApiContractAuditDetailListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/contract/auditDetailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContractExtend>,
  /**
   * No description
   * @name POST /admin/api/mallConfRefundReason/add
   * @summary add
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfRefundReason/add_POST": (data: MallConfRefundReasonAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfRefundReason/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/message/count
   * @summary 未读数量角标
   * @tags 站内信接口
   * @response `200` `JsonResultInt` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/message/count_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/message/count`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/sysRoleDataPermission/list
   * @summary 角色数据权限 列表
   * @tags 角色数据权限
   * @response `200` `JsonResultPageInfoSysRoleDataPermissionExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRoleDataPermission/list_GET": (
    query: AdminApiSysRoleDataPermissionListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysRoleDataPermission/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysRoleDataPermissionExtend>,
  /**
   * No description
   * @name POST /admin/api/building
   * @summary 楼栋 新增
   * @tags 楼栋
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/building_POST": (data: BuildingDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name DELETE /admin/api/building
   * @summary 楼栋 删除
   * @tags 楼栋
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/building_DELETE": (query: AdminApiBuildingDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/building
   * @summary 楼栋 修改
   * @tags 楼栋
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/building_PUT": (data: BuildingDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/sysRole/add
   * @summary 新增角色(返回ID)
   * @tags 系统-角色管理
   * @response `200` `JsonResultLong` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRole/add_POST": (data: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name GET /admin/api/message/list
   * @summary 消息 分页查询
   * @tags 站内信接口
   * @response `200` `JsonResultPageInfoMessage` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/message/list_GET": (query: AdminApiMessageListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/message/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMessage>,
  /**
   * No description
   * @name PUT /admin/api/contract/audit
   * @summary 合同 审核
   * @tags 合同
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/audit_PUT": (data: ContractDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/audit`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/project/pinControlChart/area
   * @summary 销控图表-租赁面积图
   * @tags 项目表
   * @response `200` `JsonResultPinContrlChartAreaVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/pinControlChart/area_GET": (
    query: AdminApiProjectPinControlChartAreaGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/pinControlChart/area`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPinContrlChartAreaVO>,
  /**
   * No description
   * @name GET /admin/api/project/pinControl/moneyChart/client
   * @summary 销控图表详情-租金、物业费收缴情况-客户回款情况
   * @tags 项目表
   * @response `200` `JsonResultListPinContrlChartMoneyClientDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/pinControl/moneyChart/client_GET": (
    query: AdminApiProjectPinControlMoneyChartClientGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/pinControl/moneyChart/client`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListPinContrlChartMoneyClientDto>,
  /**
   * No description
   * @name GET /admin/api/mallConfChannel/query
   * @summary 渠道分页查询
   * @tags 渠道
   * @response `200` `JsonResultPageInfoMallConfChannelDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfChannel/query_GET": (query: AdminApiMallConfChannelQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfChannel/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfChannelDto>,
  /**
   * No description
   * @name GET /admin/api/common/optionsBykey
   * @summary 根据key获取字典列表
   * @tags 通用
   * @response `200` `JsonResultDdExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/optionsBykey_GET": (query: AdminApiCommonOptionsBykeyGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/common/optionsBykey`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultDdExtend>,
  /**
   * No description
   * @name GET /admin/api/businessfollow/detailList
   * @summary 商机跟进(拜访记录)详情 分页查询
   * @tags 商机跟进表(拜访记录)
   * @response `200` `JsonResultPageInfoBusinessFollowExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/businessfollow/detailList_GET": (
    query: AdminApiBusinessfollowDetailListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/businessfollow/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBusinessFollowExtend>,
  /**
   * No description
   * @name POST /admin/api/project
   * @summary 项目 新增
   * @tags 项目表
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project_POST": (data: ProjectDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/project`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name DELETE /admin/api/project
   * @summary 项目 删除
   * @tags 项目表
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/project_DELETE": (query: AdminApiProjectDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/project`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/project
   * @summary 项目 修改
   * @tags 项目表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project_PUT": (data: ProjectDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/project`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name DELETE /admin/api/sysRole/delete
   * @summary 角色删除
   * @tags 系统-角色管理
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/sysRole/delete_DELETE": (query: AdminApiSysRoleDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/area
   * @summary 区域 删除
   * @tags 区域表
   * @response `200` `JsonResultInt` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/area_DELETE": (query: AdminApiAreaDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/area`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/area
   * @summary 区域 修改
   * @tags 区域表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/area_PUT": (data: AreaDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/area`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/api/area
   * @summary 区域 新增
   * @tags 区域表
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/area_POST": (data: AreaDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/area`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/clue/batchDistribute
   * @summary 线索 批量分配归属人
   * @tags 线索表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clue/batchDistribute_PUT": (data: ClueDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue/batchDistribute`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/mallConfigController/store/orderConfig
   * @summary 店铺设置-订单设置 （通用设置接口可修改） 返回参数：inventory_deductions_type,  库存扣减方式 1 下单扣减 / 2 支付扣减 under_stock_type ： 库存不足处理方式 1 允许扣为负数 2 订单自动关闭并退款  3 缺货商品自动售后并退款
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfigController/store/orderConfig_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfigController/store/orderConfig`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/api/mallConfMaterialGroup/queryAll
   * @summary 素材库分组不分页查询
   * @tags 素材库分组
   * @response `200` `JsonResultListMallConfMaterialGroupVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfMaterialGroup/queryAll_GET": (
    query: AdminApiMallConfMaterialGroupQueryAllGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfMaterialGroup/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallConfMaterialGroupVo>,
  /**
   * No description
   * @name GET /admin/api/leasingManagerSignAreaTargetConfig/monthlyIndicatorDetailReport/export
   * @summary 季度指标明细报表 导出
   * @tags 招商经理签约面积指标配置（年份）
   * @response `200` `JsonResultListMonthlyIndicatorDetailReportVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/leasingManagerSignAreaTargetConfig/monthlyIndicatorDetailReport/export_GET": (
    query: AdminApiLeasingManagerSignAreaTargetConfigMonthlyIndicatorDetailReportExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/leasingManagerSignAreaTargetConfig/monthlyIndicatorDetailReport/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMonthlyIndicatorDetailReportVO>,
  /**
   * No description
   * @name GET /admin/api/client/list
   * @summary 客户 列表 分页查询
   * @tags 客户
   * @response `200` `JsonResultPageInfoClient` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/client/list_GET": (query: AdminApiClientListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/client/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClient>,
  /**
   * No description
   * @name PUT /admin/api/clue/updateClueStatus
   * @summary 线索状态 修改
   * @tags 线索表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clue/updateClueStatus_PUT": (data: ClueDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue/updateClueStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name PUT /admin/api/contractbills/{id}
   * @summary 修改
   * @tags 合同账单表
   * @response `200` `JsonResultString` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractbills/{id}_PUT": (id: number, data: ContractBills, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractbills/${id}`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/contractbills/{id}
   * @summary 详情
   * @tags 合同账单表
   * @response `200` `JsonResultContractBills` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractbills/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractbills/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultContractBills>,
  /**
   * No description
   * @name DELETE /admin/api/contractbills/{id}
   * @summary 删除
   * @tags 合同账单表
   * @response `200` `JsonResultString` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/contractbills/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractbills/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/sysResource/menu-dragging
   * @summary 菜单拖拽
   * @tags 系统-资源管理
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource/menu-dragging_PUT": (data: MenuDragDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource/menu-dragging`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/project/addList
   * @summary 项目 批量新增
   * @tags 项目表
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/addList_POST": (data: ListDTOProjectDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/project/addList`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name GET /admin/api/clientfollow/detailList
   * @summary 客户跟进(拜访记录)详情 分页查询
   * @tags 客户跟进表(拜访记录)
   * @response `200` `JsonResultPageInfoClientFollowExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clientfollow/detailList_GET": (
    query: AdminApiClientfollowDetailListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/clientfollow/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClientFollowExtend>,
  /**
   * No description
   * @name GET /admin/api/project/queryDetailList
   * @summary 项目 查询详情列表
   * @tags 项目表
   * @response `200` `JsonResultPageInfoProjectExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/queryDetailList_GET": (
    query: AdminApiProjectQueryDetailListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/queryDetailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjectExtend>,
  /**
   * No description
   * @name GET /admin/api/contacts/list
   * @summary 联系人表分页查询
   * @tags 联系人表
   * @response `200` `JsonResultPageInfoContacts` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contacts/list_GET": (query: AdminApiContactsListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contacts/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContacts>,
  /**
   * No description
   * @name GET /admin/api/investmentStatistics/list
   * @summary 招商统计 列表 分页查询
   * @tags 招商统计
   * @response `200` `JsonResultPageInfoInvestmentStatisticsVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/investmentStatistics/list_GET": (
    query: AdminApiInvestmentStatisticsListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/investmentStatistics/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoInvestmentStatisticsVO>,
  /**
   * No description
   * @name GET /admin/api/mallConfigController/base/activityNavigation
   * @summary 装修设置-活动导航 （通用设置接口可修改）             返回参数：is_open,  是否启动 true / falseimg_url ： 导航图片url_link ： 跳转连接
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfigController/base/activityNavigation_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfigController/base/activityNavigation`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/api/common/DataPermissionList
   * @summary 数据权限 列表
   * @tags 通用
   * @response `200` `JsonResultListDataPermission` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/DataPermissionList_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/common/DataPermissionList`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListDataPermission>,
  /**
   * No description
   * @name GET /admin/api/sysResource/tree
   * @summary 获取所有资源列表（树状结构）
   * @tags 系统-资源管理
   * @response `200` `JsonResultListTreeResourceVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource/tree_GET": (query: AdminApiSysResourceTreeGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource/tree`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListTreeResourceVo>,
  /**
   * No description
   * @name GET /admin/api/contractLeaseUnit/list
   * @summary 租赁单元表 分页查询
   * @tags 租赁单元表
   * @response `200` `JsonResultPageInfoContractLeaseUnit` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractLeaseUnit/list_GET": (
    query: AdminApiContractLeaseUnitListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/contractLeaseUnit/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContractLeaseUnit>,
  /**
   * No description
   * @name GET /admin/api/contract/reportList
   * @summary 合同详情 明细报表
   * @tags 合同
   * @response `200` `JsonResultPageInfoContractExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/reportList_GET": (query: AdminApiContractReportListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/reportList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContractExtend>,
  /**
   * No description
   * @name PUT /admin/api/mallConfMaterial/move
   * @summary 移动素材到分组
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfMaterial/move_PUT": (data: MallConfMaterialMoveParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfMaterial/move`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/mallConfMaterialGroup/update
   * @summary 素材库分组修改
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfMaterialGroup/update_PUT": (data: MallConfMaterialGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfMaterialGroup/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysRole/{id}/resources
   * @summary 获取角色对应的资源集合
   * @tags 系统-角色管理
   * @response `200` `JsonResultListSysResource` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRole/{id}/resources_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/${id}/resources`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultListSysResource>,
  /**
   * No description
   * @name GET /admin/api/mallGoodsCategory/getOne
   * @summary 获取内容 三级类目 得到规格和属性
   * @tags 商品类目表
   * @response `200` `JsonResultMallGoodsCategoryVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallGoodsCategory/getOne_GET": (
    query: AdminApiMallGoodsCategoryGetOneGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallGoodsCategory/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsCategoryVo>,
  /**
   * No description
   * @name GET /admin/api/leasingManagerSignAreaTargetConfig/list
   * @summary 招商经理签约面积指标配置 列表 分页查询
   * @tags 招商经理签约面积指标配置（年份）
   * @response `200` `JsonResultPageInfoLeasingManagerSignAreaTargetConfig` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/leasingManagerSignAreaTargetConfig/list_GET": (
    query: AdminApiLeasingManagerSignAreaTargetConfigListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/leasingManagerSignAreaTargetConfig/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoLeasingManagerSignAreaTargetConfig>,
  /**
   * No description
   * @name GET /admin/api/sysRole/{id}
   * @summary 详情
   * @tags 系统-角色管理
   * @response `200` `JsonResultSysRoleVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRole/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultSysRoleVo>,
  /**
   * @description 商品类目表查询
   * @name GET /admin/api/mallGoodsCategory/getPage
   * @summary 商品类目表查询
   * @tags 商品类目表
   * @response `200` `JsonResultListMallGoodsCategoryVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallGoodsCategory/getPage_GET": (
    query: AdminApiMallGoodsCategoryGetPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallGoodsCategory/getPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallGoodsCategoryVo>,
  /**
   * No description
   * @name GET /admin/api/paymentAdvice/reportList
   * @summary 付款通知单 明细报表
   * @tags 付款通知单
   * @response `200` `JsonResultPageInfoPaymentAdviceExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/paymentAdvice/reportList_GET": (
    query: AdminApiPaymentAdviceReportListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/paymentAdvice/reportList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoPaymentAdviceExtend>,
  /**
   * No description
   * @name GET /admin/api/projectupdatelog/list
   * @summary 项目信息更新日志 分页查询
   * @tags 项目信息更新日志
   * @response `200` `JsonResultPageInfoProjectUpdateLogExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/projectupdatelog/list_GET": (query: AdminApiProjectupdatelogListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/projectupdatelog/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoProjectUpdateLogExtend>,
  /**
   * No description
   * @name PUT /admin/api/business/updateBusinessStatus
   * @summary 商机状态 修改
   * @tags 商机表
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/business/updateBusinessStatus_PUT": (data: BusinessDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business/updateBusinessStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/contractbills/query
   * @summary 合同账单表分页查询
   * @tags 合同账单表
   * @response `200` `JsonResultPageInfoContractBills` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contractbills/query_GET": (query: AdminApiContractbillsQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contractbills/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContractBills>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfMaterialGroup/delete
   * @summary 素材库分组删除
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/mallConfMaterialGroup/delete_DELETE": (
    query: AdminApiMallConfMaterialGroupDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfMaterialGroup/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/message/read
   * @summary 消息 已读（点击详情调用）
   * @tags 站内信接口
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/message/read_POST": (query: AdminApiMessageReadPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/message/read`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/business/reportList
   * @summary 商机 明细报表
   * @tags 商机表
   * @response `200` `JsonResultPageInfoBusinessExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/business/reportList_GET": (query: AdminApiBusinessReportListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business/reportList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBusinessExtend>,
  /**
   * No description
   * @name GET /admin/api/business/detailList
   * @summary 商机 详情列表
   * @tags 商机表
   * @response `200` `JsonResultPageInfoBusinessExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/business/detailList_GET": (query: AdminApiBusinessDetailListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/business/detailList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBusinessExtend>,
  /**
   * No description
   * @name GET /admin/api/clueupdatelog/query
   * @summary 线索信息更新日志表分页查询
   * @tags 线索信息更新日志表
   * @response `200` `JsonResultPageInfoClueUpdateLog` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clueupdatelog/query_GET": (query: AdminApiClueupdatelogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clueupdatelog/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClueUpdateLog>,
  /**
   * No description
   * @name GET /admin/api/common/tianyancha/search
   * @summary 天眼查 搜索
   * @tags 通用
   * @response `200` `JsonResultObject` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/tianyancha/search_GET": (
    query: AdminApiCommonTianyanchaSearchGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/common/tianyancha/search`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name POST /admin/api/contacts
   * @summary 联系人新增
   * @tags 联系人表
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contacts_POST": (data: ContactsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contacts`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/sysUser/update
   * @summary 用户修改
   * @tags 系统-用户管理
   * @response `200` `JsonResultVoid` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysUser/update_PUT": (data: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mallConfMaterial/query
   * @summary 素材库分页查询
   * @tags 素材库
   * @response `200` `JsonResultPageInfoMallConfMaterialVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallConfMaterial/query_GET": (
    query: AdminApiMallConfMaterialQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfMaterial/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfMaterialVo>,
  /**
   * No description
   * @name POST /admin/api/client
   * @summary 客户 新增
   * @tags 客户
   * @response `200` `JsonResultAddResult` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/client_POST": (data: ClientDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/client`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAddResult>,
  /**
   * No description
   * @name PUT /admin/api/client
   * @summary 客户 修改
   * @tags 客户
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/client_PUT": (data: ClientDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/client`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/oss/info
   * @summary 阿里云存储信息
   * @tags 阿里云存储
   * @response `200` `JsonResultMapStringObject` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/oss/info_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/oss/info`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMapStringObject>,
  /**
   * No description
   * @name PUT /admin/api/contract/earlyTermination
   * @summary 合同 提前终止
   * @tags 合同
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/contract/earlyTermination_PUT": (data: ContractDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/contract/earlyTermination`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfRefundReason/delete
   * @summary 售后原因删除
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/mallConfRefundReason/delete_DELETE": (
    query: AdminApiMallConfRefundReasonDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfRefundReason/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/clue/list
   * @summary 线索 列表 分页查询
   * @tags 线索表
   * @response `200` `JsonResultPageInfoClue` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/clue/list_GET": (query: AdminApiClueListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/clue/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClue>,
  /**
   * No description
   * @name POST /admin/api/mallGoodsCategory/saveOrUpdate
   * @summary 新增修改
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/mallGoodsCategory/saveOrUpdate_POST": (data: MallGoodsCategoryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallGoodsCategory/saveOrUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/api/common/optionList
   * @summary 获取列表项List
   * @tags 通用
   * @response `200` `JsonResultPageInfoDdExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/optionList_GET": (query: AdminApiCommonOptionListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/common/optionList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoDdExtend>,
  /**
   * No description
   * @name GET /admin/api/common/tianyancha/baseinfo
   * @summary 天眼查 企业基本信息
   * @tags 通用
   * @response `200` `JsonResultObject` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/common/tianyancha/baseinfo_GET": (
    query: AdminApiCommonTianyanchaBaseinfoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/common/tianyancha/baseinfo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/sysResource/{menu-id}/buttons-and-apis
   * @summary 根据菜单ID加载菜单下所有的接口资源和按钮资源
   * @tags 系统-资源管理
   * @response `200` `JsonResultButtonAndApiVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysResource/{menu-id}/buttons-and-apis_GET": (menuId: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysResource/${menuId}/buttons-and-apis`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultButtonAndApiVo>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfPage/delete
   * @summary 自定义页面删除
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `204` `object` No Content |  `401` `object` Unauthorized |  `403` `object` Forbidden
   */
  "/admin/api/mallConfPage/delete_DELETE": (
    query: AdminApiMallConfPageDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfPage/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/project/pinControlChart/money
   * @summary 销控图表-租金物业图
   * @tags 项目表
   * @response `200` `JsonResultPinContrlChartMoneyVO` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/project/pinControlChart/money_GET": (
    query: AdminApiProjectPinControlChartMoneyGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/project/pinControlChart/money`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPinContrlChartMoneyVO>,
  /**
   * No description
   * @name GET /admin/api/building/list
   * @summary 楼栋 分页查询
   * @tags 楼栋
   * @response `200` `JsonResultPageInfoBuilding` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/building/list_GET": (query: AdminApiBuildingListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBuilding>,
  /**
   * No description
   * @name PUT /admin/api/paymentAdvice/receipt
   * @summary 付款通知单 收缴确认
   * @tags 付款通知单
   * @response `200` `JsonResultInt` OK |  `201` `object` Created |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/paymentAdvice/receipt_PUT": (data: PaymentAdviceDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paymentAdvice/receipt`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/sysDept/query
   * @summary 部门表分页查询
   * @tags 系统-组织机构管理
   * @response `200` `JsonResultPageInfoSysDeptVo` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysDept/query_GET": (query: AdminApiSysDeptQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysDept/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysRole/queryAll
   * @summary 角色表不分页查询
   * @tags 系统-角色管理
   * @response `200` `JsonResultListSysRoleDto` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/sysRole/queryAll_GET": (query: AdminApiSysRoleQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListSysRoleDto>,
  /**
   * No description
   * @name GET /admin/api/client/reportList
   * @summary 客户 明细报表
   * @tags 客户
   * @response `200` `JsonResultPageInfoClientExtend` OK |  `401` `object` Unauthorized |  `403` `object` Forbidden |  `404` `object` Not Found
   */
  "/admin/api/client/reportList_GET": (query: AdminApiClientReportListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/client/reportList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoClientExtend>
};
