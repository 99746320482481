import { EAuthCode } from '~/enums/EAuthCode'
import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.investManagementClue,
    component: () => import('../../pages/investManagement/clue'),
    meta: {
      title: '招商线索',
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCLUE
    }
  },
  {
    path: routeNames.investManagementaddClue,
    component: () => import('../../pages/investManagement/addclue'),
    meta: {
      title: '线索新增',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTADDCLUE
    }
  },
  {
    path: routeNames.investManagementClueDetails,
    component: () => import('../../pages/investManagement/clueDetail'),
    meta: {
      title: '线索详情',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCLUEDETAILS
    }
  },
  {
    path: routeNames.investManagementBusiness,
    component: () => import('../../pages/investManagement/business'),
    meta: {
      title: '商机管理',
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESS
    }
  },
  {
    path: routeNames.investManagementBusinessAdd,
    component: () => import('../../pages/investManagement/businessAdd'),
    meta: {
      title: '商机管理',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESSADD
    }
  },
  {
    path: routeNames.investManagementBusinessDetail,
    component: () => import('../../pages/investManagement/businessDetail'),
    meta: {
      title: '商机管理',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESSDETAIL
    }
  },
  {
    path: routeNames.investManagementClient,
    component: () => import('../../pages/investManagement/client'),
    meta: {
      title: '客户信息管理',
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCLIENT
    }
  },
  {
    path: routeNames.investManagementClientDetails,
    component: () => import('../../pages/investManagement/clientDetails'),
    meta: {
      title: '客户信息详情',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCLIENTDETAILS
    }
  },
  {
    path: routeNames.investManagementClientAdd,
    component: () => import('../../pages/investManagement/clientAdd'),
    meta: {
      title: '客户信息',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCLIENTADD
    }
  },
  {
    path: routeNames.investManagementContract,
    component: () => import('../../pages/investManagement/contract'),
    meta: {
      title: '合同管理',
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACT
    }
  },
  {
    path: routeNames.investManagementContractAdd,
    component: () => import('../../pages/investManagement/contractAdd'),
    meta: {
      title: '合同信息',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACTADD
    }
  },
  {
    path: routeNames.investManagementContractDetail,
    component: () => import('../../pages/investManagement/contractDetail'),
    meta: {
      title: '合同详情',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACTDETAIL
    }
  },
  {
    path: routeNames.investManagementStatistics,
    component: () => import('../../pages/investManagement/statistics'),
    meta: {
      title: '招商统计',
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTSTATISTICS
    }
  },
  {
    path: routeNames.indicatorConfiguration,
    component: () => import('../../pages/investManagement/configuration'),
    meta: {
      title: '指标配置',
      hideInMenu: true,
      code: EAuthCode.INVESTMANAGEMENT_INVESTMANAGEMENTCONFIGURATION
    }
  }
] as TRoutes[]
