import { createBrowserHistory, createHashHistory } from 'history'
import iconFile from './images/icon_file.jpg'
import { generateRouteAndMenu, IRoute, routeNames } from './const'
import BasicLayout from '~/layouts/basicLayout'
import MMPNoAuth from '~/pages/403'

import generate from './modules/generates'
import _example from './modules/_example'
import departmentManagement from './modules/departmentManagement'
import basicSetting from './modules/basicSetting'
import categoryStorage from './modules/categoryStorage'
import decorationSetting from './modules/decorationSetting'
import paramsSet from './modules/paramsSet'
import statementCenter from './modules/statementCenter'
import financial from './modules/financial'
import projectManagement from './modules/projectManagement'

import Login from '../pages/login'
import { AppstoreOutlined, BgColorsOutlined, ClusterOutlined, FundOutlined, SettingOutlined, TableOutlined } from '@ant-design/icons'
import { routeType } from '~/config'
import investManagement from './modules/investManagement'

export const history = routeType === 'hash' ? createHashHistory() : createBrowserHistory()

export const routers: IRoute[] = [
  { path: routeNames.login, component: Login, meta: { title: '登录', sync: true, noLogin: true } },
  {
    path: '/',
    layout: BasicLayout,
    children: [
      // { name: '模板', path: '/_example', icon: FundOutlined, children: _example },
      { name: '项目管理', path: '/projectManagement', icon: ClusterOutlined, children: projectManagement },
      { name: '招商管理', path: '/investManagement', icon: TableOutlined, children: investManagement },
      { name: '财务管理', path: '/financial', icon: ClusterOutlined, children: financial },
      { name: '报表中心', path: '/statementCenter', icon: ClusterOutlined, children: statementCenter },
      { name: '参数配置', path: '/paramsSet', icon: ClusterOutlined, children: paramsSet },
      { name: '权限管理', path: '/departmentManagement', icon: AppstoreOutlined, children: departmentManagement },
      { path: routeNames[403], component: MMPNoAuth, meta: { sync: true, noLogin: true } }
      // { name: '基础设置', path: '/basicSetting', icon: SettingOutlined, children: basicSetting },
      // { name: '类目管理', path: '/categoryStorage', icon: ClusterOutlined, children: categoryStorage },
      // { name: '装修设置', path: '/decorationSetting', icon: BgColorsOutlined, children: decorationSetting },

      // { path: '*', component: MMPNOMatch, meta: { sync: true, noLogin: true } }
    ]
  }
]

const { routes } = generateRouteAndMenu(routers)

export function getMenus() {
  return generateRouteAndMenu(routers).menus
}

export { routes }
