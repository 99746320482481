import { FC, memo, useLayoutEffect } from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import styles from './index.module.less'

const Component: FC = () => {
  useLayoutEffect(() => {
    document.title = '没有权限访问'
  }, [])

  return (
    <div className={styles.component}>
      <Result
        status="403"
        title="403"
        subTitle="对不起,您当前无权限访问此页面"
        extra={
          <Button type="primary">
            <Link to="/"> 回首页</Link>
          </Button>
        }
      />
    </div>
  )
}

Component.displayName = 'MMPNOMatch'

const MMPNOMatch = memo(Component)
export default MMPNOMatch
