import { FC, memo, useContext } from 'react'
// import styles from './index.module.less'
import { INoMathRouteProps } from './const'
import globalContext from '~/contexts/global.context'
import { TMenu } from '~/data-model/common'
import { isSubMenu } from '~/utils/menu'
import NoMatch from '~/pages/404'
import { Redirect } from 'react-router'

const Component: FC<INoMathRouteProps> = (props) => {
  // 通过解构定义defaultProps
  const { location } = props

  const { menus } = useContext(globalContext)

  function getMenuPath(menus: TMenu[]) {
    let path = ''
    menus.every((item) => {
      if (!isSubMenu(item)) {
        return true
      }
      // 路径匹配
      if (item.path === location.pathname) {
        const [firstChild] = item.children
        // 第一个是否是嵌套路由.深度优先
        if (isSubMenu(firstChild)) {
          path = getMenuPath(firstChild.children)
        } else {
          path = firstChild.path
        }
        return false
      }
      path = getMenuPath(item.children)
      if (path) {
        return false
      }

      return true
    })
    return path
  }

  const matchPath = getMenuPath(menus)

  return matchPath ? <Redirect to={matchPath} /> : <NoMatch />
}

Component.displayName = 'NoMathRoute'

const NoMathRoute = memo(Component)
export default NoMathRoute
