import { EAuthCode } from '~/enums/EAuthCode'
import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  // {
  //   path: routeNames.departmentManagement,
  //   component: () => import('../../pages/departmentManagement'),
  //   meta: {
  //     title: '部门1管理'
  //     // , code: EAuthCode.DEPARTMENTMANAGEMENT_DEPT
  //   }
  // },
  {
    path: routeNames.employeeManagement,
    component: () => import('../../pages/departmentManagement/employeeManagement'),
    meta: {
      title: '人员配置'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_STAFF
    }
  },
  {
    path: routeNames.userJurisdiction,
    component: () => import('../../pages/departmentManagement/userJurisdiction'),
    meta: {
      title: '人员权限配置'
      , hideInMenu: true
      , code: EAuthCode.DEPARTMENTMANAGEMENT_JURISDICTION
    }
  },
  {
    path: routeNames.roleManagement,
    component: () => import('../../pages/departmentManagement/roleManagement'),
    meta: {
      title: '角色管理'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_ROLE
    }
  },
  {
    path: routeNames.resourcesManagement,
    component: () => import('../../pages/departmentManagement/resourcesManagement'),
    meta: {
      title: '资源管理'
      // , code: EAuthCode.DEPARTMENTMANAGEMENT_RESOURCE
    }
  },
  {
    path: routeNames.areaSet,
    component: () => import('../../pages/departmentManagement/areaSet'),
    meta: {
      title: '区域配置'
      , code: EAuthCode.DEPARTMENTMANAGEMENT_AREASET
    }
  },
  {
    path: routeNames.welcome,
    component: () => import('../../pages/departmentManagement/welcome'),
    meta: {
      title: '欢迎',
      hideInMenu: true
    }
  }
] as TRoutes[]
