import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
//    客户明细报表      拜访明细报表  商机明细报表  合同明细报表  线索明细报表    付款通知单报表
export default [
  {
    path: routeNames.customerDetails,
    component: () => import('../../pages/statementCenter/customerDetails'),
    meta: {
      title: '客户明细报表'
      , code: EAuthCode.STATEMENTCENTER_CUSTOMERDETAILS
    }
  },
  {
    path: routeNames.visitDetail,
    component: () => import('../../pages/statementCenter/visitDetail'),
    meta: {
      title: '拜访明细报表'
      , code: EAuthCode.STATEMENTCENTER_VISITDETAIL
    }
  },
  {
    path: routeNames.businessDetails,
    component: () => import('../../pages/statementCenter/businessDetails'),
    meta: {
      title: '商机明细报表'
      , code: EAuthCode.STATEMENTCENTER_BUSINESSDETAILS
    }
  }
  ,
  {
    path: routeNames.contractDetails,
    component: () => import('../../pages/statementCenter/contractDetails'),
    meta: {
      title: '合同明细报表'
      , code: EAuthCode.STATEMENTCENTER_CONTRACTDETAILS
    }
  }, {
    path: routeNames.threadDetails,
    component: () => import('../../pages/statementCenter/threadDetails'),
    meta: {
      title: '线索明细报表'
      , code: EAuthCode.STATEMENTCENTER_THREADDETAILS
    }
  },
  {
    path: routeNames.billheadList,
    component: () => import('../../pages/statementCenter/billheadList'),
    meta: {
      title: '付款通知单报表'
      , code: EAuthCode.STATEMENTCENTER_BILLHEADLIST
    }
  },
  {
    path: routeNames.quarterList,
    component: () => import('../../pages/statementCenter/quarter'),
    meta: {
      title: '季度指标明细报表'
      , code: EAuthCode.STATEMENTCENTER_QUARTERLIST
    }
  }
] as TRoutes[]