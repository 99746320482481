import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.paramsMaintain,
    component: () => import('../../pages/paramsSet/paramsMaintain'),
    meta: {
      title: '字典表维护'
      , code: EAuthCode.PARAMSSET_PARAMSMAINTAIN
    }
  },
  {
    path: routeNames.messageList,
    component: () => import('../../pages/message/index'),
    meta: { title: '站内信', hideInMenu: true }
  },
  {
    path: routeNames.messageDetail,
    component: () => import('../../pages/message/detail'),
    meta: { title: '站内信详情', hideInMenu: true }
  }
] as TRoutes[]