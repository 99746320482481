import React from 'react'
import GlobalContext, { IGlobalContext } from './global.context'
import { getMenus } from '../routes'
import { filterMenuByCode } from '~/routes/const'
import { systemConfig } from '~/config'
import { api } from '~/request'
import { isArray } from 'lodash'
import instance from '~/request/instance'

/**
 * 全局context的Provider
 *
 * @export
 * @class GlobalProvider
 * @extends {React.Component<any, IGlobalContext>}
 */
export default class GlobalProvider extends React.Component<any, IGlobalContext> {
  static contextType = GlobalContext

  dispatch: (key: any, value?: any) => void

  constructor(props: any) {
    super(props)

    this.dispatch = (key: any, value?: any) => {
      if (typeof key === 'string') {
        const state: any = { [key]: value }
        this.setState(state)
      } else {
        this.setState(key)
      }
    }

    this.state = {
      layoutSetting: { ...systemConfig.layoutSetting },
      breadcrumb: { ...systemConfig.breadcrumb },
      breadcrumbs: [],
      name: '',
      count: 0,
      applicationName: '美萌软件',
      menus: getMenus(),
      authCodes: [],
      dispatch: this.dispatch,
      dispatchAction: async (key: string, payload?: any) => {
        return this[key] && this[key].apply(this, [payload])
      }
    }
  }

  render() {
    return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>
  }

  /** 获取用户信息 admin/api/sysUser/current-authorities_GET */
  getUserInfo = () => {
    return api['/admin/api/sysUser/current-authorities_GET']().then(({ data }) => {
      if (data) {
        const {
          buttons = [],
          // menusCodes = [],
          username = '',
          menusTree = []
          // storeInfo = {}
        } = data
        let menusCodes = getMenusTreeCode(menusTree)
        let authCodes = buttons.concat(menusCodes)
        console.log(menusCodes, 'menusCodes')
        this.setState({
          name: username,
          authCodes: authCodes,
          // menus: getMenus()
          // menus: menusTree
          menus: filterMenuByCode(getMenus(), menusCodes) // skipAuth)
        })
      }
    })

    function getMenusTreeCode(tree: any) {
      const codes: any = []
      if (tree && isArray(tree)) {
        tree.forEach((item) => {
          let { children = [], code = '' } = item
          if (children.length) {
            const list = getMenusTreeCode(children)
            codes.push(...list)
          }
          codes.push(code)
        })
      }
      return codes
    }

    // return api['/admin/api/sysUser/LoginInfo_GET']().then(({ data }) => {
    //   if (data) {
    //     const {
    //       buttonCodes = [],
    //       // menusCodes = [],
    //       loginUserName = '',
    //       loginMobile = '',
    //       id = '',
    //       domainAccount = ''
    //       // menusTree = []
    //       // storeInfo = {}
    //     } = data
    //     // const authCodes = buttonCodes.concat(menusCodes)

    //     this.setState({
    //       name: loginUserName,
    //       authCodes: buttonCodes,
    //       menus: getMenus()
    //       // menus: menusTree,
    //       // menus: filterMenuByCode(getMenus(), menusCodes, true) // skipAuth)
    //     })
    //   }
    // })

    // INFO: 这里只是一个示例. 真实项目中你需要根据自己业务来处理侧边栏
    // return new Promise((resolve) => {
    //   this.setState({ name: 'admin', menus: getMenus() })
    //   resolve(true)
    // })
  }
  getBageCount = async () => {
    const { data } = await instance.get('/admin/api/message/count')
    this.setState({
      count: data
    })
  }
}
