import { FC, memo, useContext, useState } from 'react'
import styles from './index.module.less'
import { ISettingDrawerProps } from './const'
import { Drawer, Descriptions, Button, message, Radio, Divider, Alert } from 'antd'
import { CloseOutlined, CopyOutlined, SettingOutlined } from '@ant-design/icons'
import globalContext from '~/contexts/global.context'
import SettingDrawerFeild from './settingDrawerFeild'
import { pick } from 'lodash-es'

const { Item } = Descriptions
const { Group: RadioGroup } = Radio

const Component: FC<ISettingDrawerProps> = (props) => {
  // 通过解构定义defaultProps
  // const {} = props
  const [visible, setVisible] = useState(false)
  const context = useContext(globalContext)

  const [colors] = useState([
    'rgb(24, 144, 255)',
    'rgb(245, 34, 45)',
    'rgb(250, 84, 28)',
    'rgb(250, 173, 20)',
    'rgb(19, 194, 194)',
    'rgb(82, 196, 26)',
    'rgb(47, 84, 235)',
    'rgb(114, 46, 209))'
  ])

  const handleCopyClick = () => {
    navigator.clipboard.writeText(JSON.stringify(pick(context, ['layoutSetting', 'breadcrumb']), undefined, 2))
    message.success('已拷贝,请手动粘贴至src/config.ts文件中的systemConfig内,并刷新页面')
  }

  return (
    <>
      <Button
        icon={visible ? <CloseOutlined /> : <SettingOutlined />}
        type="primary"
        className={styles.settingDrawerStyleHandler}
        style={{ right: visible ? 300 : 0 }}
        onClick={() => setVisible((value) => !value)}
      />
      <Drawer visible={visible} className={styles.settingDrawerStyle} width={300} onClose={() => setVisible(false)}>
        <div className={styles.title}>风格</div>
        <SettingDrawerFeild title="侧边栏">
          <RadioGroup
            value={context.layoutSetting.menuTheme}
            size="small"
            onChange={(ev) => context.dispatch({ layoutSetting: { ...context.layoutSetting, menuTheme: ev.target.value! } })}
          >
            <Radio.Button value="light">light</Radio.Button>
            <Radio.Button value="dark">dark</Radio.Button>
          </RadioGroup>
        </SettingDrawerFeild>

        <SettingDrawerFeild title="顶部">
          <RadioGroup
            value={context.layoutSetting.headerTheme}
            size="small"
            onChange={(ev) => context.dispatch({ layoutSetting: { ...context.layoutSetting, headerTheme: ev.target.value! } })}
          >
            <Radio.Button value="light">light</Radio.Button>
            <Radio.Button value="dark">dark</Radio.Button>
          </RadioGroup>
        </SettingDrawerFeild>

        <Divider />

        <div className={styles.title}>面包屑导航</div>
        <SettingDrawerFeild title="样式">
          <RadioGroup
            value={context.breadcrumb.type}
            size="small"
            onChange={(ev) => context.dispatch({ breadcrumb: { ...context.breadcrumb, type: ev.target.value! } })}
          >
            <Radio.Button value="none">不显示</Radio.Button>
            <Radio.Button value="simple">简洁</Radio.Button>
            <Radio.Button value="normal">正常</Radio.Button>
          </RadioGroup>
        </SettingDrawerFeild>

        <Divider />

        <div className={styles.title}>定制主题色</div>
        <Descriptions column={1} size="small">
          <Item>
            <div className="textTip">
              <div>
                antd使用less编译样式.如需修改请前往 <strong style={{ color: '#333333' }}>src/styles/theme.less</strong> 中进行设置
              </div>
              <div>以下是推荐主色(点击可复制)</div>
            </div>
          </Item>
          <Item>
            {colors.map((backgroundColor) => (
              <span
                style={{ backgroundColor }}
                key={backgroundColor}
                className={styles.themeColor}
                onClick={() => {
                  window.navigator.clipboard.writeText(`@primary-color: ${backgroundColor};`)
                  message.info(`${backgroundColor} copied`)
                }}
              />
            ))}
          </Item>
        </Descriptions>

        <Divider />
        <Alert message="配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件(src/config.ts)中的systemConfig配置" type="warning" />
        <Button icon={<CopyOutlined />} block style={{ marginTop: 12 }} onClick={handleCopyClick}>
          拷贝配置
        </Button>
      </Drawer>
    </>
  )
}

Component.displayName = 'SettingDrawer'

const SettingDrawer = memo(Component)
export default SettingDrawer
