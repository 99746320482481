import { FC, memo, useContext, useEffect, useState } from 'react'
import styles from './index.module.less'
import { ILoginFormProps } from './const'
import { EyeInvisibleOutlined, EyeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import globalContext from '~/contexts/global.context'
import { useLocation, useHistory } from 'react-router-dom'
import { api } from '~/request'
import { ECodeScene } from '~/enums/ECodeScene'
import { isMobilePhone } from '~/modules/@wmeimob/backend-pro/src/utils/validator'
import { Input, Button, Form } from 'antd'
import { defaultPwd } from '~/config'
import { encryptPassword } from '../../const'
import { routeNames } from '~/routes/const'

const { Item, useForm } = Form

const Component: FC<ILoginFormProps> = (props) => {
  const { dispatchAction } = useContext(globalContext)
  const { state } = useLocation<{ from?: string }>()
  const history = useHistory()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [passwordType, setPasswordType] = useState('password')

  useEffect(() => {
    // form.setFieldsValue({ mobile: 'shanxl', password: defaultPwd })
  }, [])

  function handleFinish() {
    form.validateFields().then(async () => {
      setLoading(true)

      const { domainAccount, password } = form.getFieldsValue()
      // const encrypted = encryptPassword(password)
      //'Bearer ' +   , "domainAccount": "yhj"  (data?.replace('Wmeimob_', '') || '')

      try {
        let { data } = await api['/admin/public/login_POST']({ domainAccount, password })
        getCustomer()
        window.localStorage.setItem('Authorization', data || '')
        await dispatchAction('getUserInfo')
        setLoading(false)
        console.log(state)
        if (state && state.from && state.from !== window.location.href) {
          window.location.href = state.from
        } else {
          history.push({ pathname: routeNames.welcome })
        }
      } catch (error) {
        setLoading(false)
      }
    })
  }
  async function getCustomer() {
    const { data } = await api['/admin/api/sysUser/LoginInfo_GET']({})
    localStorage.setItem('LoginInfo', JSON.stringify(data))
  }

  return (
    <Form labelCol={{ span: 6 }} labelAlign="left" form={form} onFinish={handleFinish}>
      <Item name="domainAccount" validateFirst rules={[{ required: true, message: '请输入账号' }]}>
        <Input placeholder="请输入账号" maxLength={20} size="large" prefix={<UserOutlined style={{ color: '#bfbfbf' }} />} />
      </Item>

      {/* <Item
        name="mobile"
        validateFirst
        rules={[
          { required: true, message: '请输入手机号码' },
          { validator: (_, value) => (isMobilePhone(value) ? Promise.resolve() : Promise.reject(new Error('请输入正确的手机号码'))) }
        ]}
      >
        <Input placeholder="请输入手机号码" size="large" prefix={<UserOutlined style={{ color: '#bfbfbf' }} />} />
      </Item> */}
      <Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
        <Input
          placeholder="请输入密码"
          size="large"
          prefix={<LockOutlined style={{ color: '#bfbfbf' }} />}
          type={passwordType}
          suffix={
            passwordType === 'password' ? (
              <EyeInvisibleOutlined onClick={() => setPasswordType('text')} />
            ) : (
              <EyeOutlined onClick={() => setPasswordType('password')} />
            )
          }
        />
      </Item>

      {/* <Form.Item noStyle>
        <div className={styles.opeartions}>
          <Button type="link" onClick={() => props.onClick(ECodeScene.FORGOT)}>
            忘记密码?
          </Button>
          <Button type="link" onClick={() => props.onClick(ECodeScene.REG)}>
            前往注册
          </Button>
        </div>
      </Form.Item> */}

      <Item>
        <Button type="primary" block size="large" htmlType="submit" loading={loading}>
          登录
        </Button>
      </Item>
    </Form>
  )
}

Component.displayName = 'LoginForm'

const LoginForm = memo(Component)
export default LoginForm
