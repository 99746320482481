import { cloneDeep } from 'lodash'
import { ISubMenu, IMenus, IBaseRouteMenu } from '~/data-model/common'

export const routeNames = {
  eTable: '/_example/table',
  eForm: '/_example/formPro',
  login: '/login',
  register: '/register',
  403: '/403',
  // 设置
  ...{
    basicSetting: '/basicSetting',
    advertisingSpaceAdd: '/advertisingSpace/add',
    advertisingSpace: '/advertisingSpace',
    channelMaintain: '/channelMaintain',
    defaultSearch: '/defaultSearch',
    hotSearch: '/hotSearch',
    materialLibrary: '/decorationSetting/materialLibrary'
  },
  ...{
    departmentManagement: '/departmentManagement',
    employeeManagement: '/employeeManagement',
    roleManagement: '/roleManagement',
    companyResourcesGroup: '/companyResourcesGroup',
    companyResourcesGroupEdit: '/companyResourcesGroup/edit',
    userJurisdiction: '/userJurisdiction',
    areaSet: '/areaSet',
    welcome: '/welcome',
    resourcesManagement: '/departmentManagement/resourcesManagement'
  },
  ...{
    decorationManageList: '/decorationManage/list',
    decorationManageEdit: '/decorationManage/list/edit',
    activityNavigation: '/activityNavigation',
    materialLibrary: '/materialLibrary'
  },
  ...{
    categoryStorageManagement: '/categoryStorage/management'
  },
  ...{
    paramsMaintain: '/paramsSet/paramsMaintain',
    messageList: '/messageList',
    messageDetail: '/messageDetail'
  },
  ...{
    // 报表中心
    customerDetails: '/statementCenter/customerDetails',
    visitDetail: '/statementCenter/visitDetail',
    businessDetails: '/statementCenter/businessDetails',
    contractDetails: '/statementCenter/contractDetails',
    threadDetails: '/statementCenter/threadDetails',
    billheadList: '/statementCenter/billhead',
    quarterList: '/statementCenter/quarter'
  },
  ...{
    // 财务管理
    billhead: '/financial/billhead',
    noticePrint: '/financial/noticePrint',
    noticeDetail: '/financial/noticeDetail',
    backlog: '/financial/backlog',
    contractReview: '/financial/contractReview'
  },
  ...{
    // 项目管理
    projectInfoList: '/projectManagement/projectInfoList',
    pinControlChartList: '/projectManagement/pinControlChartList',
    projectOp: '/projectManagement/projectOp',
    projectEdit: '/projectManagement/projectEdit',
    projectDetail: '/projectManagement/projectDetail',
    pinControlChartDetail: '/projectManagement/pinControlChartDetail'
  },
  ...{
    investManagement: '/investManagement',
    investManagementClue: '/investManagement/clue',
    investManagementaddClue: '/investManagement/addclue',
    investManagementClientDetails: '/investManagement/clientDetails',
    investManagementClientAdd: '/investManagement/clientAdd',
    investManagementClient: '/investManagement/client',
    investManagementBusiness: '/investManagement/business',
    investManagementBusinessAdd: '/investManagement/businessAdd',
    investManagementBusinessDetail: '/investManagement/businessDetail',
    investManagementContract: '/investManagement/contract',
    investManagementContractAdd: '/investManagement/contractAdd',
    investManagementContractDetail: '/investManagement/contractDetail',
    investManagementClueDetails: '/investManagement/clueDetail',
    investManagementStatistics: '/investManagement/statistics',
    indicatorConfiguration: '/investManagement/configuration'
  }
}

export type RouteCode = string | string[]

/**
 * 菜单路由
 * 这个是用于配置那些路由应该显示在菜单内
 */
export interface MenuRoute extends IBaseRouteMenu {
  /** 菜单名称 */
  name: string
  /** 菜单权限code */
  code?: RouteCode
  /** 嵌套子级 */
  children?: IRoute[]
}

/**
 * 布局路由
 * 由于配置一些列的路由共享同一套布局
 */
export interface LayoutRoute {
  /** 布局名称 */
  name?: string
  /** 路径 */
  path: string
  /** 布局组件 */
  layout: any
  /**
   * 页面转发
   * 设置为string表示跳转至指定路由
   * 设置为true表示跳转至菜单权限中第一个路由页面.当菜单有权限控制时这会非常有用
   * @default true 布局组件默认执行重定向操作
   */
  redirect?: string | boolean
  /** 嵌套子级 */
  children?: IRoute[]
}

/** 路径路由 */
export interface TRoutes extends IBaseRouteMenu {
  /** 路由路径 */
  path: string
  /** 路由组件 */
  component: any
  /** 路由元数据 */
  meta?: {
    /**
     * 标题
     * 用于菜单栏显示以及浏览器标签头部显示
     * */
    title?: string
    /** 页面权限code */
    code?: RouteCode
    /** 是否不显示在菜单栏 */
    hideInMenu?: boolean
    /**
     * 是否显示在菜单栏
     * 只有当你的路由是配置在顶级页面.无subMenu时.你才需要配置这个属性用户将他显示在菜单栏中
     * */
    showInMenu?: boolean
    /**
     * 是否是同步组件
     *
     * 默认情况下.路由都是基于页面级的code-split,并且在渲染过程总使用异步组件做了包裹
     * 如果说组件需要成为同步组件.那么你需要用sync进行显式的声明
     * */
    sync?: boolean
    /**
     * 是否不需要登录权限
     * 如果页面不需要登录验证.将此值设置为true
     * */
    noLogin?: boolean

    [i: string]: any
  }
  /**
   * 嵌套路由
   * @deprecated ⚠️已废弃 不建议使用嵌套路由
   */
  // children?: RLRoute[]
}

export type IRoute = MenuRoute | TRoutes | LayoutRoute

export type RLRoute = TRoutes | LayoutRoute

export interface IAuthSubMenu extends ISubMenu {
  code?: RouteCode
  children: (IAuthSubMenu | IAutMenu)[]
}

export interface IAutMenu extends IMenus {
  code?: RouteCode
}

export function isLayoutRoute(data: IRoute): data is LayoutRoute {
  return !!(data as LayoutRoute).layout
}

export function isMenuRoute(data: IRoute): data is MenuRoute {
  return !isLayoutRoute(data) && !!(data as MenuRoute).name
}

export function isRoute(data: IRoute): data is TRoutes {
  return !isLayoutRoute(data) && !isMenuRoute(data)
}

/**
 * 转换路由和菜单数据
 *
 */
export function generateRouteAndMenu(data: IRoute[]) {
  const menudata: (IAuthSubMenu | IAutMenu)[] = []

  function formatIRoute(data: IRoute[], menu?: IAuthSubMenu) {
    return data.reduce((results, item) => {
      if (isMenuRoute(item)) {
        const { children = [], name, ...rest } = item

        const subMenu: IAuthSubMenu = { ...rest, title: name, children: [] }
        if (menu) {
          menu.children.push(subMenu)
        } else {
          menudata.push(subMenu)
        }

        const childrenRoutes = formatIRoute(children, subMenu)
        results = results.concat(childrenRoutes)
      } else if (isLayoutRoute(item)) {
        if (item.children && item.children.length) {
          item.children = formatIRoute(item.children)
        }
        results.push(item)
      } else {
        const { meta = {}, path, icon, iconImg } = item
        const { hideInMenu, showInMenu, code } = meta
        const title = meta.title || path
        // 菜单赋值
        if (menu && !hideInMenu) {
          menu.children.push({ title, path, code, icon, iconImg })
        }
        if (showInMenu) {
          menudata.push({ title, path, code, icon, iconImg })
        }
        results.push(item)
      }

      return results
    }, [] as RLRoute[])
  }

  // 过滤空菜单
  function filterEmptyChildren(data: typeof menudata) {
    return data.filter((item) => {
      if (!isAuthMenu(item)) {
        const children = filterEmptyChildren(item.children || [])
        if (!children.length) {
          return false
        }
      }
      return true
    })
  }

  const routes = formatIRoute(cloneDeep(data))

  return {
    menus: filterEmptyChildren(menudata),
    routes
  }
}

function isAuthMenu(data: IAuthSubMenu | IAutMenu): data is IAutMenu {
  return !(data as IAuthSubMenu).children
}

/**
 * 是否有code
 *
 * @param {string[]} codes
 * @param {RouteCode} [code]
 * @return {*}
 */
function hasCode(codes: string[], code?: RouteCode) {
  return !code ? false : typeof code === 'string' ? codes.indexOf(code) !== -1 : !!codes.find((cs) => code.indexOf(cs) !== -1)
}

/**
 * 根据权限code过滤菜单栏
 *
 * @export
 * @param {((IAuthSubMenu | IAutMenu)[])} data 菜单数据
 * @param {string[]} [authCodes=[]] 权限code
 * @param {boolean} [skipAuth=false] 是否不检验code 用于开发环境跳过校验
 * @return {*}
 */
export function filterMenuByCode(data: (IAuthSubMenu | IAutMenu)[], authCodes: string[] = [], skipAuth = false) {
  return data.reduce((obj, item) => {
    if (isAuthMenu(item)) {
      const hasAuth = skipAuth || !item.code || hasCode(authCodes, item.code)
      if (hasAuth) {
        obj.push(item)
      }
    } else {
      let { code, children = [] } = item
      const hasAuth = skipAuth || !code || hasCode(authCodes, code)
      if (hasAuth) {
        if (children.length) {
          children = filterMenuByCode(children, authCodes, skipAuth)
        }

        if (children.length) {
          obj.push({ ...item, children })
        }
      }
    }

    return obj
  }, [] as (IAuthSubMenu | IAutMenu)[])
}
