import ReactDOM from 'react-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import './styles/global.less'
import reportWebVitals from './reportWebVitals'
import GlobalProvider from './contexts/GlobalProvider'
import RouterLayout from './layouts/routerLayout'
import SettingDrawer from './layouts/settingDrawer'
import { isDevelopment } from './config'

// 配置中文语言包
dayjs.locale('zh-cn')

ReactDOM.render(
  // INFO: antd 使用了findNode.导致无法开启StrictMode
  // <React.StrictMode>
  <GlobalProvider>
    <ConfigProvider locale={zhCN}>
      <RouterLayout />
      {isDevelopment && <SettingDrawer />}
    </ConfigProvider>
  </GlobalProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
