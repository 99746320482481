export enum EAuthCode {
  /** 权限管理 */
  DEPARTMENTMANAGEMENT = 'departmentManagement',
  /** 权限管理-区域配置 */
  DEPARTMENTMANAGEMENT_AREASET = 'departmentManagement-areaSet',
  /** 权限管理-区域配置-新增 */
  DEPARTMENTMANAGEMENT_AREASET_ADD = 'departmentManagement-areaSet-add',
  /** 权限管理-区域配置-编辑 */
  DEPARTMENTMANAGEMENT_AREASET_UPDATE = 'departmentManagement-areaSet-update',
  /** 权限管理-区域配置-删除 */
  DEPARTMENTMANAGEMENT_AREASET_DELETE = 'departmentManagement-areaSet-delete',

  /** 权限管理-人员配置 */
  DEPARTMENTMANAGEMENT_STAFF = 'departmentManagement-staff',
  /** 权限管理-人员配置-预览 */
  DEPARTMENTMANAGEMENT_STAFF_QUERY = 'departmentManagement-staff-query',
  /** 权限管理-人员配置-新增 */
  DEPARTMENTMANAGEMENT_STAFF_ADD = 'departmentManagement-staff-add',
  /** 权限管理-人员配置-编辑 */
  DEPARTMENTMANAGEMENT_STAFF_UPDATE = 'departmentManagement-staff-update',
  /** 权限管理-人员配置-删除 */
  DEPARTMENTMANAGEMENT_STAFF_DELETE = 'departmentManagement-staff-delete',
  /** 权限管理-人员配置-重置密码 */
  DEPARTMENTMANAGEMENT_STAFF_RESETPWD = 'departmentManagement-staff-resetPWD',
  /** 权限管理-人员配置-权限 */
  DEPARTMENTMANAGEMENT_STAFF_RULE = 'departmentManagement-staff-rule',

  /** 权限管理-角色管理 */
  DEPARTMENTMANAGEMENT_ROLE = 'departmentManagement-role',
  /** 权限管理-角色管理-预览 */
  DEPARTMENTMANAGEMENT_ROLE_QUERY = 'departmentManagement-role-query',
  /** 权限管理-角色管理-新增 */
  DEPARTMENTMANAGEMENT_ROLE_ADD = 'departmentManagement-role-add',
  /** 权限管理-角色管理-编辑 */
  DEPARTMENTMANAGEMENT_ROLE_UPDATE = 'departmentManagement-role-update',
  /** 权限管理-角色管理-删除 */
  DEPARTMENTMANAGEMENT_ROLE_DELETE = 'departmentManagement-role-delete',

  /** 权限管理-资源管理 */
  DEPARTMENTMANAGEMENT_RESOURCE = 'departmentManagement-resource',

  /** 权限管理-人员权限配置 */
  DEPARTMENTMANAGEMENT_JURISDICTION = 'departmentManagement-jurisdiction',

  /** 参数配置 */
  PARAMSSET = 'paramsSet',
  /** 参数配置-字典表维护 */
  PARAMSSET_PARAMSMAINTAIN = 'paramsSet-paramsMaintain',
  /** 参数配置-字典表维护-编辑 */
  PARAMSSET_PARAMSMAINTAIN_UPDATE = 'paramsSet-paramsMaintain-update',

  /** 项目管理 */
  PROJECTMANAGEMENT = 'projectManagement',
  /** 项目管理-项目信息维护 */
  PROJECTMANAGEMENT_PROJECTINFOLIST = 'projectManagement-projectInfoList',
  /** 项目管理-项目信息维护-编辑 */
  PROJECTMANAGEMENT_PROJECTINFOLIST_UPDATE = 'projectManagement-projectInfoList-update',
  /** 项目管理-项目信息维护-新增 */
  PROJECTMANAGEMENT_PROJECTINFOLIST_ADD = 'projectManagement-projectInfoList-add',
  /** 项目管理-项目信息维护-删除 */
  PROJECTMANAGEMENT_PROJECTINFOLIST_DELETE = 'projectManagement-projectInfoList-delete',

  /** 项目管理-项目新增 */
  PROJECTMANAGEMENT_PROJECTOP = 'projectManagement-projectOp',

  /** 项目管理-项目编辑 */
  PROJECTMANAGEMENT_PROJECTEDIT = 'projectManagement-projectEdit',

  /** 项目管理-项目详情 */
  PROJECTMANAGEMENT_PROJECTDETAIL = 'projectManagement-projectDetail',

  /** 项目管理-销控图 */
  PROJECTMANAGEMENT_PINCONTROLCHARTLIST = 'projectManagement-pinControlChartList',

  /** 项目管理-销控图详情 */
  PROJECTMANAGEMENT_PINCONTROLCHARTDETAIL = 'projectManagement-pinControlChartDetail',

  /** 财务管理 */
  FINANCIAL = 'financial',
  /** 财务管理-付款通知单 */
  FINANCIAL_BILLHEAD = 'financial-billhead',
  /** 财务管理-付款通知单-收缴 */
  FINANCIAL_BILLHEAD_CAPTURE = 'financial-billhead-capture',

  /** 财务管理-通知单打印 */
  FINANCIAL_NOTICEPRINT = 'financial-noticePrint',

  /** 财务管理-付款单详情 */
  FINANCIAL_NOTICEDETAIL = 'financial-noticeDetail',

  /** 财务管理-财务代办 */
  FINANCIAL_BACKLOG = 'financial-backlog',
  /** 财务管理-财务代办-审核 */
  FINANCIAL_BACKLOG_REVIEW = 'financial-backlog-review',
  /** 财务管理-财务代办-分配 */
  FINANCIAL_BACKLOG_ALCT = 'financial-backlog-alct',
  /** 报表中心 */
  STATEMENTCENTER = 'statementCenter',
  /** 报表中心-客户明细报表 */
  STATEMENTCENTER_CUSTOMERDETAILS = 'statementCenter-customerDetails',

  /** 报表中心-拜访明细报表 */
  STATEMENTCENTER_VISITDETAIL = 'statementCenter-visitDetail',

  /** 报表中心-商机明细报表 */
  STATEMENTCENTER_BUSINESSDETAILS = 'statementCenter-businessDetails',

  /** 报表中心-合同明细报表 */
  STATEMENTCENTER_CONTRACTDETAILS = 'statementCenter-contractDetails',

  /** 报表中心-线索明细报表 */
  STATEMENTCENTER_THREADDETAILS = 'statementCenter-threadDetails',

  /** 报表中心-付款通知单报表 */
  STATEMENTCENTER_BILLHEADLIST = 'statementCenter-billheadList',
  /** 报表中心-季度指标明细报表 */
  STATEMENTCENTER_QUARTERLIST = 'statementCenter-quarterList',

  /** 招商管理 */
  INVESTMANAGEMENT = 'investManagement',
  /** 招商管理-招商线索 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLUE = 'investManagement-investManagementClue',
  /** 招商管理-招商线索-编辑 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLUE_UPLOAD = 'investManagement-investManagementClue-upload',
  /** 招商管理-招商线索-新增 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLUE_ADD = 'investManagement-investManagementClue-add',
  /** 招商管理-招商线索-删除 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLUE_DELETE = 'investManagement-investManagementClue-delete',
  /** 招商管理-招商线索-分配 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLUE_ALLOCATION = 'investManagement-investManagementClue-allocation',

  /** 招商管理-线索新增 */
  INVESTMANAGEMENT_INVESTMANAGEMENTADDCLUE = 'investManagement-investManagementaddClue',

  /** 招商管理-线索详情 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLUEDETAILS = 'investManagement-investManagementClueDetails',

  /** 招商管理-商机管理 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESS = 'investManagement-investManagementBusiness',
  /** 招商管理-商机管理-编辑 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESS_UPLOAD = 'investManagement-investManagementBusiness-upload',
  /** 招商管理-商机管理-新增 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESS_ADD = 'investManagement-investManagementBusiness-add',
  /** 招商管理-商机管理-删除 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESS_DELETE = 'investManagement-investManagementBusiness-delete',
  /** 招商管理-商机管理-分配 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESS_ALCT = 'investManagement-investManagementBusiness-alct',
  /** 招商管理-商机管理-激活 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESS_ACTIVATE = 'investManagement-investManagementBusiness-activate',

  /** 招商管理-商机操作 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESSADD = 'investManagement-investManagementBusinessAdd',

  /** 招商管理-商机详情 */
  INVESTMANAGEMENT_INVESTMANAGEMENTBUSINESSDETAIL = 'investManagement-investManagementBusinessDetail',

  /** 招商管理-客户信息管理 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLIENT = 'investManagement-investManagementClient',
  /** 招商管理-客户信息管理-编辑 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLIENT_UPLOAD = 'investManagement-investManagementClient-upload',
  /** 招商管理-客户信息管理-新增 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLIENT_ADD = 'investManagement-investManagementClient-add',
  /** 招商管理-客户信息管理-分配 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLIENT_ALLOCATION = 'investManagement-investManagementClient-allocation',

  /** 招商管理-客户信息操作 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLIENTADD = 'investManagement-investManagementClientAdd',

  /** 招商管理-客户信息详情 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCLIENTDETAILS = 'investManagement-investManagementClientDetails',

  /** 招商管理-合同管理 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACT = 'investManagement-investManagementContract',
  /** 招商管理-合同管理-编辑 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACT_UPLOAD = 'investManagement-investManagementContract-upload',
  /** 招商管理-合同管理-删除 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACT_DELETE = 'investManagement-investManagementContract-delete',
  /** 招商管理-合同管理-新增 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACT_ADD = 'investManagement-investManagementContract-add',
  /** 招商管理-合同管理-分配 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACT_ALCT = 'investManagement-investManagementContract-alct',

  /** 招商管理-合同信息操作 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACTADD = 'investManagement-investManagementContractAdd',

  /** 招商管理-合同详情 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONTRACTDETAIL = 'investManagement-investManagementContractDetail',
  /** 招商管理-招商统计 */
  INVESTMANAGEMENT_INVESTMANAGEMENTSTATISTICS = 'investManagement-investManagementStatistics',
  /** 招商管理-指标配置 */
  INVESTMANAGEMENT_INVESTMANAGEMENTCONFIGURATION = 'investManagement-investManagementConfiguration'
}